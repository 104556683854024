import React from 'react';
import '../css/auth.css';

export default function TermsConditions({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>×</button>
        <h2>Terms and Conditions</h2>
        
        <p className="intro">
          Welcome to Goleezy.com. By accessing or using our marketplace, you agree to comply with and be bound by the following terms and conditions. Please read them carefully. If you do not agree to these terms, you should not use our website or services.
        </p>
        
        <div className="terms-section">
          <h3>1. Definitions</h3>
          <ul>
            <li><strong>Goleezy.com</strong>: Refers to the online rental marketplace owned and operated by Goleezy, Inc.</li>
            <li><strong>User</strong>: Any individual or entity accessing or using the Goleezy.com platform.</li>
            <li><strong>Renter</strong>: A user who rents items through the Goleezy.com platform.</li>
            <li><strong>Owner</strong>: A user who lists items for rent on the Goleezy.com platform.</li>
            <li><strong>Item</strong>: Any product listed for rent on Goleezy.com.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>2. User Accounts</h3>
          <ul>
            <li>Users must create an account to access certain features of Goleezy.com.</li>
            <li>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</li>
            <li>Users must provide accurate and complete information when creating an account and must update their information if it changes.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>3. Listings and Rentals</h3>
          <ul>
            <li>Owners must provide accurate descriptions, pricing, and availability for their items.</li>
            <li>Renters must use rented items responsibly and return them in the same condition as when rented, subject to reasonable wear and tear.</li>
            <li>Goleezy.com is not responsible for the accuracy of listings or the condition of items.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>4. Payments and Fees</h3>
          <ul>
            <li>Payment for rentals is processed through Goleezy.com’s payment system.</li>
            <li>Goleezy.com may charge service fees to Owners and Renters. These fees will be clearly disclosed during the transaction process.</li>
            <li>Owners are responsible for any taxes associated with rental income.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>5. Cancellations and Refunds</h3>
          <ul>
            <li>Cancellation policies vary by listing and are specified by the Owner. Renters must review and agree to the cancellation policy before renting an item.</li>
            <li>Refunds are subject to the cancellation policy of the listing. Goleezy.com may facilitate refunds but is not responsible for disputes between Users.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>6. User Conduct</h3>
          <ul>
            <li>Users must comply with all applicable laws and regulations when using Goleezy.com.</li>
            <li>Users must not post false, misleading, or defamatory content.</li>
            <li>Users must not use Goleezy.com for illegal or unauthorized purposes.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>7. Dispute Resolution</h3>
          <ul>
            <li>Users agree to resolve disputes directly with each other whenever possible.</li>
            <li>If a resolution cannot be reached, Goleezy.com may offer mediation services but is not obligated to intervene.</li>
            <li>Any disputes arising out of or in connection with these terms shall be governed by the laws of the jurisdiction in which Goleezy.com operates.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>8. Limitation of Liability</h3>
          <ul>
            <li>Goleezy.com is not liable for any direct, indirect, incidental, or consequential damages arising from the use of the platform.</li>
            <li>Goleezy.com does not guarantee the availability, accuracy, or quality of items listed on the platform.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>9. Indemnification</h3>
          <ul>
            <li>Users agree to indemnify and hold harmless Goleezy.com, its affiliates, and employees from any claims, damages, or expenses arising out of their use of the platform or violation of these terms.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>10. Changes to Terms</h3>
          <ul>
            <li>Goleezy.com reserves the right to modify these terms at any time. Users will be notified of significant changes through the platform.</li>
            <li>Continued use of the platform after changes are made constitutes acceptance of the new terms.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>11. Termination</h3>
          <ul>
            <li>Goleezy.com may terminate or suspend a user’s account at any time for any reason, including but not limited to violations of these terms.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>12. Contact Information</h3>
          <p>For any questions or concerns regarding these terms, please contact us at info@goleezy.com.</p>
        </div>
      </div>
    </div>
  );
}
