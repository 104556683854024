

// import React, { useState ,forwardRef } from 'react';
// import '../css/productCard.css'; // Import CSS file
// import Heart from "../Assets/heart-svgrepo-com.svg";
// import Eye from "../Assets/eye-svgrepo-com.svg";
// import QuickViewCard from '../components/QuickViewCard';

// const ProductCard = forwardRef(({ images, name, address, price }, ref) => {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   const handlePrevClick = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNextClick = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   return (
//     <>
//     <div className="product-card" ref={ref}>
//       <div className="carousel-product-card">
//         <button className="product-card-arrow-btn left" style={{display:currentImageIndex===0?'none':'flex'}} onClick={handlePrevClick}>
//         &larr;
//         </button>
//         <img src={images[currentImageIndex]} alt={name} className="product-image" />
//         <button className="product-card-arrow-btn right" style={{display:currentImageIndex===(images.length - 1)?'none':'flex'}} onClick={handleNextClick}>
//         &rarr;
//         </button>
//         {/* <div className='carousel-dots-cont'>
//         <div className="carousel-dots">
//         {images.map((_, index) => (
         
//           <span
//             key={index}
//             className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
//           >
//           </span>
//         ))}
//         </div>
//       </div> */}

//       <div className="carousel-dots-cont">
//   <div className="carousel-dots">
//     {images.map((_, index) => {
//       const distance = Math.abs(index - currentImageIndex);
//       const size = Math.max(10 - distance * 2, 4); // Decrease size with distance but keep minimum size

//       return (
//         <span
//           key={index}
//           className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
//           style={{
//             width: `${size}px`,
//             height: `${size}px`,
//           }}
//         ></span>
//       );
//     })}
//   </div>
// </div>

//       </div>

//       <div className="product-info">
//         <h3 className="product-name">{name}</h3>
//         <p className="product-address">{address}</p>
//         <p className="product-price">{price}</p>
//       </div>

//      <div className="product-actions">
//         <div className="actions">
//           <button className="quick-view">
//             <img src={Eye} alt="View" />
//             <span className="tooltip">Quick View</span>
//           </button>
          
//           <button className="compare">
//             <img src={Heart} alt="Compare" />
//             <span className="tooltip">Add to Compare</span>
//           </button>
          
//           <button className="like">
//             <img src={Heart} alt="Like" />
//             <span className="tooltip">Add to Favorites</span>
//           </button>
//         </div>
        
//         <p className="views-count">277 views</p>
//       </div>
      
//     </div>

//     <QuickViewCard data={{
//       images, name, address, price
//     }}/>
//     </>
//   );
// });

// export default ProductCard;



import React, { useState, forwardRef } from 'react';
import '../css/productCard.css';
import Heart from "../Assets/heart-svgrepo-com.svg";
import Eye from "../Assets/eye-svgrepo-com.svg";
import QuickViewCard from '../components/QuickViewCard';

const ProductCard = forwardRef(({ images, name, address, price }, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isQuickViewVisible, setIsQuickViewVisible] = useState(false);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openQuickView = () => {
    setIsQuickViewVisible(true);
  };

  const closeQuickView = () => {
    setIsQuickViewVisible(false);
  };

  return (
    <>
      <div className="product-card" ref={ref}>
        <div className="carousel-product-card">
          <button
            className="product-card-arrow-btn left"
            style={{ display: currentImageIndex === 0 ? 'none' : 'flex' }}
            onClick={handlePrevClick}
          >
            &larr;
          </button>
          <img src={images[currentImageIndex]} alt={name} className="product-image" />
          <button
            className="product-card-arrow-btn right"
            style={{ display: currentImageIndex === images.length - 1 ? 'none' : 'flex' }}
            onClick={handleNextClick}
          >
            &rarr;
          </button>

          <div className="carousel-dots-cont">
            <div className="carousel-dots">
              {images.map((_, index) => {
                const distance = Math.abs(index - currentImageIndex);
                const size = Math.max(10 - distance * 2, 4);

                return (
                  <span
                    key={index}
                    className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  ></span>
                );
              })}
            </div>
          </div>
        </div>

        <div className="product-info">
          <h3 className="product-name">{name}</h3>
          <p className="product-address">{address}</p>
          <p className="product-price">{price}</p>
        </div>

        <div className="product-actions">
          <div className="actions">
            <button className="quick-view" onClick={openQuickView}>
              <img src={Eye} alt="View" />
              <span className="tooltip">Quick View</span>
            </button>
            
            <button className="compare">
              <img src={Heart} alt="Compare" />
              <span className="tooltip">Add to Compare</span>
            </button>
            
            <button className="like">
              <img src={Heart} alt="Like" />
              <span className="tooltip">Add to Favorites</span>
            </button>
          </div>
          
          <p className="views-count">277 views</p>
        </div>
      </div>

      {isQuickViewVisible && (
        <QuickViewCard
          data={{ images, name, address, price,category:'Others',timeAgo:'4 months ago',views:'409 ' }}
          onClose={closeQuickView}
        />
      )}
    </>
  );
});

export default ProductCard;
