import { useState, useEffect } from 'react';
import axios from 'axios';
// import Cookies from 'js-cookie';

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // const token = Cookies.get('token');
        // if (!token) {
        //   setAuthenticated(false);
        //   return setLoading(false);
        // }

        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/userdetails`, {
          withCredentials: true // Automatically include cookies
        });

        setAuthenticated(response.status === 200);
      } catch (error) {
        console.error('Authentication error:', error);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return { authenticated, loading,setAuthenticated };
};

export default useAuth;
