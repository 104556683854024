import Navbar from "./navbar";
import Footer from "./footer";
import React from "react";
import "../css/Package.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Package = ({ title, price, features, searchnearyou }) => {
  return (
    <>
      <div className="package">
        <div className="packageHead">
          <h3>{title}</h3>
        </div>
        <div className="packageprice">
          <p className="price">₹{price}</p>
        </div>
        <div className="packagebody">
          <button>Choose this package</button>
          <div className="packagesearch">
          <h2>{searchnearyou}</h2>
          </div>
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: "8px" }} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const Packages = () => {
  const packages = [
    {
      title: "7 Days Showcase",
      price: 49,
      searchnearyou: "Top of the search near you for 7 days",
      features: [
        "Duration: 7 Days",
        "Featured: 7 Days",
        "Bump up: 1x",
        "Bump up every: 7 Days",
      ],
    },
    {
      title: "30 Days Showcase",
      price: 99,
      searchnearyou: "Top of the search near you for 30 days",
      features: [
        "Duration: 30 Days",
        "Featured: 30 Days",
        "Bump up: 1x",
        "Bump up every: 7 Days",
      ],
    },
  ];

  return (
    <>
      <Navbar />
      <div className="packages-container">
        <div className="headingprofile">
          <h1>Purchase New Package</h1>
        </div>
        {packages.map((pkg, index) => (
          <Package
            key={index}
            title={pkg.title}
            price={pkg.price}
            features={pkg.features}
            searchnearyou={pkg.searchnearyou} // Pass the searchnearyou prop
          />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Packages;
