import React, { useEffect, useState,useRef } from "react";
import "../css/productpage.css";
import {
  FaMapMarkerAlt,
  FaUser,
  FaClock,
  FaEye,
  FaRocketchat,
} from "react-icons/fa";
// import {  FaTimes } from 'react-icons/fa';
import Navbar from "./navbar";
import Footer from "./footer";
import ProductCard from "../pages/productPageCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import Zoomin from "../Assets/zoom-in-1-svgrepo-com.svg";
import {
  FaThumbsUp,
  FaExclamationTriangle,
  FaRegThumbsUp,
  FaHeart,
  FaBalanceScale,
  FaFacebook,
  FaPrint,
} from "react-icons/fa";
// import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCircle,
  faMobileAlt,faPaperclip,
  faStar,
faPlus
} from "@fortawesome/free-solid-svg-icons";

import { faFacebookF, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faUser, faMessage } from "@fortawesome/free-regular-svg-icons";
import AttachIcon from "../Assets/attach-svgrepo-com.svg";
import reportIcon from '../Assets/diamond-exclamation.svg'
import ImageForProductPage from "../components/ImageForProductPage";
import ImagePopup from "../components/imageForProdPop";
import SuggestionProductPageSlider from "../components/SuggestionProductPageSlider";
const ProductPage = () => {
  const [width, setwidth] = useState(0);
  // const [rating, setRating] = useState(0);  
  const [review, setReview] = useState(""); // State to track the review text
  const [viewarrow, setviewarrow] = useState("block"); // State to track the review text
  const [currentListingIndex, setCurrentListingIndex] = useState(0); // State to track the current listing index
  const [isZoomed, setIsZoomed] = useState(false); // State to control zoom modal visibility
  const [currentImage, setCurrentImage] = useState(""); // State for the current zoomed image

  const productListRef = useRef(null);
  const firstProductCardRef = useRef(null);
  
  // Open the zoom modal with the selected image
  const handleZoomIn = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsZoomed(true);
  };

  // Close the zoom modal
  const handleZoomOut = () => {
    setIsZoomed(false);
  };

  const [rating, setRating] = useState(0); // Final saved rating
  const [hoverRating, setHoverRating] = useState(0); // Temporary hover rating

  // Update hover rating based on current star hover
  const handleMouseEnter = (star) => {
    setHoverRating(star);
  };

  // Save the last hovered rating when the mouse leaves
  const handleMouseLeave = () => {
    setRating(hoverRating);
  };
  const handleNextListing = () => {
    setCurrentListingIndex((prevIndex) => (prevIndex + 1) % listings.length);
  };

  const handlePrevListing = () => {
    setCurrentListingIndex(
      (prevIndex) => (prevIndex - 1 + listings.length) % listings.length
    );
  };
  const isMobile = window.innerWidth <= 768;
  useEffect(() => {
    setwidth(window.innerWidth);
    setviewarrow(isMobile ? "none" : "block");
  });
  const listings = [
    {
      name: "Gold Plates Flower Necklace",
      vendor: "Gurjeet Jewelleries",
      location: {
        address:
          "Delhi Safdarjung Railway Station Rd, CSC Suvidha Market, New Moti Bagh, New Delhi, Delhi 110023, India",
        city: "New Delhi",
        state: "Delhi",
        postal_code: "110023",
        country: "India",
      },
      price_per_day: "₹100",
      image_url:
        "https://goleezy.com/wp-content/uploads/2024/07/IMG_20240715_163713-360x240.jpg.webp",
    },
    {
      name: "Study Table",
      vendor: "Study Table",
      location: {
        address:
          "Bandra Kurla Complex Rd, Bharat Nagar, Bandra East, Mumbai, Maharashtra, India",
        city: "Mumbai",
        state: "Maharashtra",
        postal_code: "400051", // Add postal code if available
        country: "India",
      },
      price_per_day: "₹80",
      image_url:
        "https://goleezy.com/wp-content/uploads/2024/07/14E35754-0739-4894-B72A-FBFB2949D29D-360x240.jpeg.webp",
    },
    {
      name: "Toyota Fortuner",
      vendor: "Toyota Fortuner",
      location: {
        address: "New Vaddem, Goa 403802, India",
        city: "Goa",
        state: "Goa",
        postal_code: "403802",
        country: "India",
      },
      price_per_day: "₹7,000",
      image_url:
        "https://goleezy.com/wp-content/uploads/2024/06/1cfc0123-c14d-4947-bdec-6f6ce02d7148-360x240.jpg.webp",
    },
    {
      name: "Need for Speed Payback",
      vendor: "Need for Speed Payback",
      location: {
        address: "Delhi, India",
        city: "Delhi",
        state: "Delhi",
        country: "India",
      },
      price_per_day: "₹300",
      image_url:
        "https://goleezy.com/wp-content/uploads/2024/06/1de3e474-88cd-4605-8339-55f62ee2febc-360x240.jpg.webp",
    },
    {
      name: "SPIDER MAN 2",
      vendor: "SPIDER MAN 2",
      location: {
        address: "Sector 32A, Ludhiana, Punjab, India",
        city: "Ludhiana",
        state: "Punjab",
        country: "India",
      },
      price_per_day: "₹500",
      image_url:
        "https://goleezy.com/wp-content/uploads/2024/06/f5fdae27-dc1a-4445-aa9b-603ebdc38f97-360x240.jpg.webp",
    },
  ];

  const products = [
    {
      image:
        "https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp",
      name: "Product 1",
      address: "Location 1",
      price: "100/Day",
    },
    {
      image:
        "https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp",
      name: "Product 2",
      address: "Location 2",
      price: "150/Day",
    },
    {
      image:
        "https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp",
      name: "Product 2",
      address: "Location 2",
      price: "150/Day",
    },
    {
      image:
        "https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp",
      name: "Product 2",
      address: "Location 2",
      price: "150/Day",
    },
    {
      image:
        "https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp",
      name: "Product 2",
      address: "Location 2",
      price: "150/Day",
    },
    {
      image:
        "https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp",
      name: "Product 2",
      address: "Location 2",
      price: "150/Day",
    },
  ];

  const desc = {
    sections: [
      {
        heading:
          "Rent the Perfect Ride Creta Automatic for Your Next Adventure",
        paragraph:
          "When it comes to finding the perfect vehicle for your travel needs, the Creta Automatic stands out as a top choice. Whether you’re planning a family vacation or a business trip, or just need a reliable car for daily commutes, renting a Creta Automatic offers a blend of comfort, efficiency, and style. At Go Leezy, we provide a range of rental options to ensure you have the best driving experience possible. Let’s dive into why the Creta Automatic is an excellent choice for your next rental.",
      },
      {
        heading: "Experience the Luxury of the Creta Automatic",
        paragraph:
          "The Creta Automatic is synonymous with luxury and convenience. Designed with the latest automotive technology, this vehicle ensures a smooth and effortless drive. The automatic transmission allows for a hassle-free experience, especially in city traffic, making it an ideal option for both urban and long-distance driving. The interior is spacious, with plush seating that guarantees comfort for all passengers, making it perfect for family trips or business travel.",
      },
      {
        heading: "Fuel Efficiency and Performance",
        paragraph:
          "One of the standout features of the Creta Automatic is its remarkable fuel efficiency. The advanced engine technology ensures that you get the best mileage, reducing the frequency of stops at the fuel station. This not only saves money but also makes your journey more enjoyable and less interrupted. Coupled with its robust performance, the Creta Automatic offers a dynamic driving experience, whether you are cruising on highways or navigating through city streets.",
      },
      {
        heading: "Safety First",
        paragraph:
          "At Go Leezy, we understand that safety is a top priority for our customers. The Creta Automatic is equipped with advanced safety features, including multiple airbags, ABS with EBD, and rear parking sensors. These features provide an added layer of security, ensuring peace of mind while you are on the road. The car’s sturdy build and reliable braking system further enhance its safety credentials, making it a dependable choice for any trip.",
      },
      {
        heading: "Modern Features for a Modern Driver",
        paragraph:
          "The Creta Automatic is packed with modern features designed to enhance your driving experience. From a state-of-the-art infotainment system that keeps you connected and entertained, to climate control that ensures a comfortable cabin environment, every detail is crafted with the driver and passengers in mind. The user-friendly interface and easy-to-use controls make every drive a pleasure, whether you are heading to a business meeting or exploring new destinations.",
      },
      {
        heading: "Why Choose Go Leezy for Your Creta Automatic Rental?",
        paragraph:
          "Go Leezy is committed to providing top-notch rental services that meet and exceed customer expectations. When you rent a Creta Automatic from us, you benefit from: Competitive Pricing, Flexible Rental Options, Excellent Customer Service, Well-Maintained Vehicles.",
      },
      {
        heading: "Book Your Creta Automatic Today!",
        paragraph:
          "Don’t miss out on the opportunity to drive a top-of-the-line vehicle without the commitment of ownership. Renting a Creta Automatic from Go Leezy gives you the freedom to enjoy all its benefits without long-term responsibilities. Visit our website at Go Leezy to book your rental today and experience the joy of driving a Creta Automatic. Whether for business or leisure, we have you covered for all your travel needs.",
      },
      {
        heading: "Additional Information",
        paragraph:
          "1) Free pickup/drop-Anjuna, arpora, baga, calangute, candolim\n2) Token amount for prebooking:\nA) 1k for hatchback cars\nB) 2k for creta/thar\n3) Airport pickup(9am-5pm)-500 Drop-500\n(5pm-9am) -1000 drop-1000\n4) Security deposit: Hatchback-3000 SUV- 5000\n5) After 9am full day is chargeable.",
      },
    ],
  };

  const fileInputRef = useRef(null);

  // Function to trigger the file input when clicking on the div
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      // Handle file upload logic here
    }
  };


  const [text, setText] = useState('');
  const maxCharacters = 200;
  const minCharacters = 0; // Change this if you have a specific minimum character requirement

  const handleTextChange = (e) => {
    if (e.target.value.length <= maxCharacters) {
      setText(e.target.value);
    }
  };

  const [isZoom,setIsZoom]=useState(false);
 

  const handleZoom=()=>{
    
    setIsZoom(!isZoom)

  }

  const category = "Cars";
  return (
    
    <div className="prodP-Supreme">
      <Navbar />

      {isZoom &&<ImagePopup
           handleZoom={handleZoom}
           images={['https://goleezy.com/wp-content/uploads/2024/06/986D8E75-39B8-4809-B1DD-6C57C401E42A.jpeg','https://goleezy.com/wp-content/uploads/2024/06/49324940-AEED-4A5D-8C2A-508A7F72A619.jpeg','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7.jpeg']}
                
    />}

      <div className="breadcrumb-container-parent">
      <div className="breadcrumb-container">
          <div className="breadcrumb">
            <span className="black-ur">Home</span>
            <span className="arrow-ur">›</span>
            <span className="black-ur">Search Results</span>
            <span className="arrow-ur">›</span>
            <span className="black-ur">Cars</span>
            <span className="arrow-ur">›</span>
            <span className="current">Creta automatic</span>
          </div>
          <div className="breadcrumb-icons">
            <div className="breadcrumb-icon fas-sh">
              <FontAwesomeIcon icon={faFacebookF} />
              <span className="product-icons-tooltip">
                        Share on Facebook
              </span>
            </div>
            <div className="breadcrumb-icon twit-sh">
              <FontAwesomeIcon icon={faXTwitter} />
              <span className="product-icons-tooltip">
                        Share on Twitter
              </span>
            </div>
          </div>
        </div>
      </div>


      <div className='product-page-cnt'>
        
          {/* <div className="product-page-sub-cnt"> */}
            <div className="product-page">
              <div className="product-content">
                
                

                {/* <div className="product-page-img-cont"> */}
          <div className="product-page-img-cont" >
           
              <ImageForProductPage
                handleZoom={handleZoom}
                images={[products[2].image,products[1].image,'https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp']}
               
              />
            {/* ))} */}
          </div>
        {/* </div> */}




                <div className="Prod_desc">
                  <div className="prod-icons">
                    <div className="prod_left_icons">
                      <div className="prod_icon">
                        <FaUser
                          className="icons"
                          style={{
                            backgroundColor: "#DDECF7",
                            fontSize: "12px",
                          }}
                        />
                      </div>
                      <span>Private</span>
                    </div>
                    <div className="prod_right_icons">
                      <div className="prod_icon">
                        <FaClock
                          className="icons"
                          style={{ backgroundColor: "#DDECF7" }}
                        />
                      </div>
                      <span>3 months ago</span>
                      <div className="prod_icon">
                        <FaEye
                          className="icons"
                          style={{ backgroundColor: "#DDECF7" }}
                        />
                      </div>
                      <span>379 Views</span>
                    </div>
                  </div>
                  <div className="Product-heading">
                    <h2 style={{ color: "#374B5C", fontSize: "30px" }}>
                      Creta Automatic
                    </h2>
                  </div>

                  <p className="Category">Category: {category}</p>

                  <div className="product-location">
                    <div className="Icon-l">
                      <FaMapMarkerAlt className="loc_icon" />
                    </div>
                    Goa Velha, Goa, India{" "}
                    <a href="" className="SeeMap-btn">
                      See map
                    </a>
                  </div>

                  <div className="product-price">
                    <p className="Price">
                      <strong>₹3,500/Day</strong>
                    </p>
                  </div>
                  <div className="price-period-div">
                    <p className="price-period">Price Per Day: ₹3,500/Day</p>
                  </div>
                  <div className="product-description">
                    <h3 style={{ marginTop: "20px" }}>Description</h3>
                    {desc.sections.map((section, index) => (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        <h2>{section.heading}</h2>
                        <p>{section.paragraph}</p>
                      </div>
                    ))}
                    <h3 style={{ marginTop: "20px" }}>Features</h3>
                    <div style={{ color: "#374B5C", marginTop: "15px" }}>
                      Price Per Day: ₹3,500/Day
                    </div>

                    <h3 style={{ marginTop: "20px" }}>Location</h3>
                    <div className="map-div">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d984968.5162908217!2d72.757570132704!3d15.347579280801714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfba106336b741%3A0xeaf887ff62f34092!2sGoa!5e0!3m2!1sen!2sin!4v1731517014800!5m2!1sen!2sin"
                        width="100%"
                        height="323"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Map of Goa"
                      ></iframe>
                    </div>
                  </div>

                  <h3
                    style={{
                      color: "#374B5C",
                      fontSize: "24px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "700",
                      borderTop: " 2px solid #F2F4F8",
                      borderBottom: "2px solid #F2F4F8",
                      marginTop: "30px",
                    }}
                  >
                    Write a Review
                  </h3>
                  <div>
                    <div className="Ratings">
                    <div className="profile-section-cont">
                      <div className="profile-section">
                        <FaUser
                          style={{
                            fontSize: "30px",
                            color: "#374B5C",
                            marginRight: "10px",
                          }}
                        />
                      </div>

                      <p>
                        <b>Post as Guest</b>
                        <br></br>
                        Your opinion matters
                      </p>
                      
                      </div>
                      

                      {/* Rating */}
                      <div className="rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            onMouseEnter={() => handleMouseEnter(star)}
            onMouseLeave={handleMouseLeave}
            style={{
              cursor: 'pointer',
              color: star <= (hoverRating || rating) ? '#E9E017' : '#ccc',
            }}
          >
            <FontAwesomeIcon icon={faStar}  style={{height:'24px',width:'24px'}}/>
          </span>
        ))}
      </div>
                    </div>

                    <form className="review-form">
                      {/* <div className="add-photos">
                        
                        <FontAwesomeIcon className="add-photo-sp" icon={faPaperclip} />
                        <p>Add Photos</p>
                      </div> */}

                      <div className="add-photos" onClick={handleUploadClick} style={{ cursor: 'pointer' }}>
      <FontAwesomeIcon className="add-photo-sp" icon={faPaperclip} />
      <p>Add Photos</p>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the file input
      />
    </div>
    <input
                        type="text"
                        placeholder="Your Name"
                        required
                        className="Rev_inp"
                      />
                      
                      <textarea
                        placeholder="Write your review"
                        maxLength="200"
                        className="Rev_txt"
                        value={text}
                       onChange={handleTextChange}
                      ></textarea>
                       <div className="character-info">
        <span className={`min-characters ${text.length >= minCharacters ? 'valid' : ''}`}>
          Minimum characters: {minCharacters} 
        </span>
        <span className="character-count">
          {text.length}/{maxCharacters} characters
        </span>
      </div>
                      <button type="submit" className="submit-rev-btn"><span>Submit Review</span> <FontAwesomeIcon icon={faPlus} /></button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="site_imfo">
                <div className="user_profile">
                  <div className="user_app_info">
                    <div className="icon_area">
                      <FontAwesomeIcon icon={faUser} className="inner" />
                    </div>
                    <div className="user_per_info">
                      <a href="#">rajsinghal</a>
                      <h3 style={{ marginTop: "5px" }}>
                        Member since: 4 months
                      </h3>
                      <h3 style={{ marginTop: "5px" }}>
                        Account type: private
                      </h3>
                      <h4>User is offline</h4>
                      <a href="#" id="adds">
                        See all ads
                      </a>
                    </div>
                  </div>
                  <div className="cont_info">
                    <FontAwesomeIcon icon={faMobileAlt} className="icon" />
                    <a href="#">+917536810010</a>
                  </div>
                  <div className="chat_button">
                    <Button type="button">
                      <FontAwesomeIcon
                        icon={faMessage}
                        style={{ height: "16px", width: "16px" }}
                      />{" "}
                      Chat
                    </Button>
                  </div>
                </div>
                <div className="product-icons_">
                  <div className="product-icons-child">
                    <div className="icon hea">
                      <FaHeart style={{ fontSize: "16px" }} />
                      <span className="product-icons-tooltip">
                        Add to Favorites
                      </span>
                    </div>
                    <div className="icon com">
                      <FaBalanceScale style={{ fontSize: "16px" }} />
                      <span className="product-icons-tooltip">
                        Add to Compare
                      </span>
                    </div>
                    <div className="icon fas">
                      <FaFacebook style={{ fontSize: "16px" }} />
                      <span className="product-icons-tooltip">
                        Share on Facebook
                      </span>
                    </div>
                    <div className="icon prin">
                      <FaPrint
                        style={{ fontSize: "16px" }}
                        className="faPrin"
                      />
                      <span className="product-icons-tooltip">Print</span>
                    </div>
                  </div>
                </div>

                <div className='report-cnt'>

                   <img className="report-img" src={reportIcon} alt='report-error'></img>
                   <span className="report-span">Report abuse</span>
          
                </div>


                <div className="Featured listings">
                  <div className="headings">
                    <h1 >Featured listings</h1>
                  </div>
                  <div className="Listings">
                    {/* Show only the current listing */}
                    <ProductCard
                      // key={currentListingIndex}
                      // image={listings[currentListingIndex].image_url}
                      // name={listings[currentListingIndex].name}
                      // address={listings[currentListingIndex].location.address}
                      // price={listings[currentListingIndex].price_per_day}
                      // isFeatured={true}
                      // style={{ width: "390px", height: "488px" }}
                      listings={listings}
                    />
                    {/* <div className="listing-controls">
                      <span className="pageno">
                        {currentListingIndex + 1}/{listings.length}
                      </span>
                      <div className="buttons">
                        <button className="prev" onClick={handlePrevListing} style={{backgroundColor:(currentListingIndex+1)===1?'#dcecdc':'#308616'}} disabled={(currentListingIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <button className="next" onClick={handleNextListing} style={{backgroundColor:currentListingIndex+1===listings.length?'#dcecdc':'#308616'}}  disabled={currentListingIndex+1===listings.length}>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SuggestionProductPageSlider buttonMsg={"Display all from goacarrentals"} headingMsg={"More from this user"}  buttonMobMsg={"Display All"}/>
       
      
      
       
      <Footer />
    </div>
  );
};       

export default ProductPage;
