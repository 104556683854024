// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* home.css */
.carousel-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 2vh;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA,aAAa;AACb;IACI,UAAU;IACV,cAAc;IACd,gBAAgB;EAClB","sourcesContent":["/* home.css */\n.carousel-container {\n    width: 80%;\n    margin: 0 auto;\n    padding-top: 2vh;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
