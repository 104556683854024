// import React from 'react';
// import '../css/productCard.css'; // Import CSS file
// import '../css/productPageCard.css';
// import Heart from "../Assets/heart-svgrepo-com.svg";
// import Eye from "../Assets/eye-svgrepo-com.svg";

// const ProductPageCard = ({ listings}) => {
//   return (
//     <div className='productPageCard-Supreme'>
//     {listings.map((listing, index) => (
//     <div className="product-card productPageCard" style={{ width: '100%', padding: '30px'}}>
//       <img src={listing.image} alt={listing.name} className="product-image" />
//       <div className="product-info productPageCard-info" style={{padding: '0px'}}>
//         <h3 className="product-name">{listing.name}</h3>
//         <p className="product-address">{listing.address}</p>
//         <p className="product-price">{listing.price}</p>
//       </div>
      
      
      
//     </div>))}
//     </div>
//   );
// };

// export default ProductPageCard;


import React, { useState,useEffect } from "react";
import "../css/productCard.css"; // Import CSS file
import "../css/productPageCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";


const ProductPageCard = ({ listings }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [Val, setVal] = useState(100); // Initial value of Val

  // Update Val based on screen width
  const updateVal = () => {
    const width = window.innerWidth;
    if (width >= 1050) {
      setVal(100); // Full width for PC
    } else if (width >= 768 && width < 1050) {
      setVal(50); // Half width for tablets
    } else {
      setVal(100); // Full width for smaller devices
    }
  };

  useEffect(() => {
    updateVal(); // Set initial Val based on current screen width
    window.addEventListener("resize", updateVal); // Add resize listener
    return () => {
      window.removeEventListener("resize", updateVal); // Clean up listener
    };
  }, []);

  // Handlers for navigation
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? listings.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === listings.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="productPageCard-Supreme" >
     
      <div className="slider-wrapper">
        <div
          className="card-slider"
          style={{
            transform: `translateX(-${currentIndex * Val}%)`,
            display: "flex",
            transition: "transform 0.5s ease-in-out",
          }}
        >
          {listings.map((listing, index) => (
            <div
              key={index}
              className="product-card productPageCard "
              // style={{
              //   width: "100%",
              //   padding: "30px",
              //   display:  "block",
              // }}
            >
              <img
                src={listing.image_url}
                alt={listing.name}
                className="product-image"
              />
              <div
                className="product-info productPageCard-info"
                style={{ padding: "0px" }}
              >
                <h3 className="product-name">{listing.name}</h3>
                <p className="product-address">{listing.location.address}</p>
                <p className="product-price">{listing.price_per_day}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      


      <div className="listing-controls">
                      <span className="pageno">
                        {currentIndex + 1}/{listings.length}
                      </span>
                      <div className="buttons">
                        <button className="prev" onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#dcecdc':'#308616'}} disabled={(currentIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <button className="next" onClick={handleNextClick} style={{backgroundColor:Val===50 ? (currentIndex+1===listings.length-1?'#dcecdc':'#308616'):(currentIndex+1===listings.length?'#dcecdc':'#308616') }}  disabled={Val===50 ? currentIndex+1===listings.length-1:currentIndex+1===listings.length }>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                    </div>
    </div>
  );
};

export default ProductPageCard;
