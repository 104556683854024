import React from "react";
import box from "../../Assets/box-svgrepo-com.svg";
import "../../css/MyAds.css"
const MyAds = () => (
    <div className="my-ads">
    <div className="MyAds">
        <h1>You do not have any ad yet.</h1>
        <div className="Box">
<img src={box}></img>
        </div>
   <button className='PostMyAdd-btn'>Post Your Ad</button>
    </div>
    </div>
);

export default MyAds;
