import React, { useState } from 'react';
import '../css/auth.css';
import Navbar from './navbar';
import axios from 'axios';
import AUTH_LOGO from '../Assets/auth_logo.WEBP';
import { useNavigate } from 'react-router-dom';
import Footer from './footer';

export default function ForgotPass() {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(false);
  const navigate = useNavigate();
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startOtpTimer = () => {
    if(isTimerRunning)
    {
      return;
    }
    setTimer(60);
    setResendEnabled(false);
    setIsTimerRunning(true);
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setResendEnabled(true);
          setIsTimerRunning(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleResendOtp = () => {
    handleSendOtp();
    setOtp('');
  };

  const isPasswordValid = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password);
  };

  const validateInput = () => {
    let newErrors = {};

    if (!emailOrPhone) {
      newErrors.emailOrPhone = 'Email or phone is required';
    }

    if (isOtpSent && !otp) {
      newErrors.otp = 'OTP is required';
    }

    if (toggle) {
      if (!password) {
        newErrors.password = 'Password is required';
      } else if (!isPasswordValid(password)) {
        newErrors.password = 'Password must contain uppercase, lowercase, number, special character, and be 8 characters or longer';
      }

      if (password !== confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSendOtp = async () => {
    if (!validateInput()) return;

    try {
      if(!isTimerRunning){
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/get-otp-forgot`, { identifier: emailOrPhone }, { withCredentials: true });
      if(result.status===201)
      {
      setIsOtpSent(true);
      startOtpTimer();
      }
    }
    } catch (error) {
      console.error('Error sending OTP!', error);
      window.location.reload();
    }
  };

  const handleVerifyOtp = async () => {
    if (!validateInput()) return;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/verify-otp-forgot`, { identifier: emailOrPhone, otpCode: otp }, { withCredentials: true });
      if(result.status===201)
      {
      setToggle(true);
      }
    } catch (error) {
      if(error.response.data.msg==='Invalid OTP')
        {
          setErrors({ ...errors, otp: 'Invalid Otp' });
       
        }else{
      console.error('Error verifying OTP!', error);
      window.location.reload();
        }
    }
  };

  const handleChangePass = async () => {
    if (!validateInput()) return;

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/change-password`, { identifier: emailOrPhone, password }, { withCredentials: true });
      if(result.status===201)
      {
        alert("Password Change Successfully!")
      navigate('/auth');
      }
    } catch (error) {
      console.error('Error changing password!', error);
      window.location.reload();
    }
  };

  return (
    <>
      <Navbar />
      <div className='auth-container'>
        <div className='auth-logo-container'>
          <img src={AUTH_LOGO} className='auth-logo-img' alt="Auth Logo" />
        </div>

        <div className='auth-box'>
          {!toggle && (
            <>
              <div className='input-group'>
                <input 
                  type="text"
                  placeholder=" "
                  className={`input-field ${errors.emailOrPhone ? 'input-error' : ''}`}
                  value={emailOrPhone}
                  onChange={(e) => {
                    setEmailOrPhone(e.target.value);
                    setErrors({ ...errors, emailOrPhone: '' });
                  }}
                  disabled={isOtpSent}
                />
                <label className={`${errors.emailOrPhone? 'label-error-floating':'label-floating' }`}>Email/Phone</label>
                {errors.emailOrPhone && <p className="error-message">{errors.emailOrPhone}</p>}
              </div>

              {isOtpSent && (
                <div className='input-group'>
                  <input 
                    type="text"
                    placeholder=" "
                    className={`input-field ${errors.otp ? 'input-error' : ''}`}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                      setErrors({ ...errors, otp: '' });
                    }}
                  />
                  <label className={`${errors.otp? 'label-error-floating':'label-floating' }`}>OTP</label>
                  {errors.otp && <p className="error-message">{errors.otp}</p>}
                </div>
              )}

              {!isOtpSent && <button className='auth-button' onClick={handleSendOtp}>Send OTP</button>}
              {isOtpSent && <button className='auth-button' onClick={handleVerifyOtp}>Verify OTP</button>}
              {isOtpSent && timer > 0 && <div className="otp-timer">Resend OTP in {timer} seconds</div>}
              {isOtpSent && resendEnabled && <button className='auth-button resend-button' onClick={handleResendOtp}>Resend OTP</button>}
            </>
          )}

          {toggle && (
            <>
              <div className='input-group'>
                <input
                  type="password"
                  placeholder=" "
                  className={`input-field ${errors.password ? 'input-error' : ''}`}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors({ ...errors, password: '' });
                  }}
                />
                <label className={`${errors.password? 'label-error-floating':'label-floating' }`}>Password</label>
                {errors.password && <p className="error-message">{errors.password}</p>}
              </div>

              <div className='input-group'>
                <input
                  type="password"
                  placeholder=" "
                  className={`input-field ${errors.confirmPassword ? 'input-error' : ''}`}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setErrors({ ...errors, confirmPassword: '' });
                  }}
                />
                <label className={`${errors.confirmPassword? 'label-error-floating':'label-floating' }`}>Confirm Password</label>
                {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
              </div>

              <button className='auth-button' onClick={handleChangePass}>Change Password</button>
            </>
          )}
        </div>
      </div>
      <Footer/>
    </>
  );
}
