import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import '../css/navbar.css'
import VoiceIconImage from '../Assets/voice-icon.png'
import SearchIconImage from '../Assets/searching-magnifying-glass.png'

const SearchComponent = () => {
    const [searchText, setSearchText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [showBorder, setShowBorder] = useState(false);
    const [gifUrl, setGifUrl] = useState('');

    // useEffect(() => {
    //   if (showBorder) {
    //     // Append a unique timestamp to the URL to force reload
    //     setGifUrl(`https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/searchbar_border4.gif?${new Date().getTime()}`);
    //   } else {
    //     setGifUrl('none');
    //   }
    // }, [showBorder]);

    useEffect(() => {
        if (showBorder) {
            // Automatically remove the border after 5 seconds
            const timer = setTimeout(() => {
                
                setShowBorder(false);
                setGifUrl('none')
            }, 6500);

            // Clean up the timer when the component unmounts or `showBorder` changes
            return () => clearTimeout(timer);
        }else if(isFocused!==true)
        {
            setShowBorder(true)
            setGifUrl(`https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/searchbar_border4.gif?${new Date().getTime()}`);

        }
    }, [showBorder,isFocused]);

  const handleFocus = () => {
    setGifUrl('none');
    setIsFocused(true);
    setShowBorder(false)
  };

  const handleBlur = () => {
    setGifUrl(`https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/searchbar_border4.gif?${new Date().getTime()}`);
    setShowBorder(true)
    setIsFocused(false);
  };

    // Function to handle voice input
    const handleVoiceInput = () => {
        if (!('webkitSpeechRecognition' in window)) {
            alert("Your browser does not support voice recognition.");
            return;
        }
        
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = "en-US"; // Set language
        recognition.continuous = false;
        recognition.interimResults = false;

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setSearchText(transcript); // Update input with recognized text
        };

        recognition.onerror = (error) => {
            console.error("Voice recognition error:", error);
        };

        recognition.start();
    };

    return ( 
        // <div className={`search-container-mobile ${isFocused ? 'no-animation' : ''}`}  style={{border:isFocused ? '1px solid #308616' : '1px solid black'}}>
           <div className="OneBefo">
           <div className={`${showBorder? 'twoBefore':'twoBefore'}`} style={{ backgroundImage: `url(${gifUrl})`}}>
        <div
        className={`search-container-mobile  ${isFocused ? 'no-animation' : ''}`}
        style={{ border: isFocused ? '1px solid #308616' : '1px solid black' }}
    >
    

            <img style={{ height: '20px'}} src={SearchIconImage} alt="search-icon" className="icon search-icon" />
            <input
                className="search-input-mobile"
                placeholder="Find cars, bikes, freelancers..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
           
            <img style={{ height: '20px'}} src={VoiceIconImage}  alt="voice-icon" className="icon mic-icon-mobile" onClick={handleVoiceInput}></img>
        </div>
        </div>
        </div> 
    );
};

export default SearchComponent;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch, faMicrophone } from "@fortawesome/free-solid-svg-icons";
// import '../css/navbar.css';
// import VoiceIconImage from '../Assets/voice-icon.png';
// import SearchIconImage from '../Assets/searching-magnifying-glass.png';

// const SearchComponent = () => {
//     const [searchText, setSearchText] = useState("");
//     const [isFocused, setIsFocused] = useState(false);
//     const [showBorder, setShowBorder] = useState(true);

//     useEffect(() => {
//         if (showBorder) {
//             const timer = setTimeout(() => {
//                 setShowBorder(false);
//             }, 5000);  // Automatically remove the border after 5 seconds

//             return () => clearTimeout(timer);  // Cleanup the timer
//         }
//     }, [showBorder]);

//     const handleFocus = () => {
//         setIsFocused(true);
//         setShowBorder(true);  // Add border on focus
//     };

//     const handleBlur = () => {
//         setIsFocused(false);
//     };

//     // Handle voice input functionality
//     const handleVoiceInput = () => {
//         if (!('webkitSpeechRecognition' in window)) {
//             alert("Your browser does not support voice recognition.");
//             return;
//         }

//         const recognition = new window.webkitSpeechRecognition();
//         recognition.lang = "en-US";
//         recognition.continuous = false;
//         recognition.interimResults = false;

//         recognition.onresult = (event) => {
//             const transcript = event.results[0][0].transcript;
//             setSearchText(transcript);  // Update input with recognized text
//         };

//         recognition.onerror = (error) => {
//             console.error("Voice recognition error:", error);
//         };

//         recognition.start();
//     };

//     return (
//         <div
//             className={`search-container-mobile ${showBorder ? 'animate-border' : ''} ${isFocused ? 'focused' : ''}`}
//             style={{
//                 border: isFocused ? '1px solid #308616' : '1px solid black',
//                 backgroundImage: showBorder ? "url('https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/searchbar_border4.gif')" : 'none',
//                 backgroundSize: '100% 100%',
//                 backgroundRepeat: 'no-repeat',
//                 transition: 'all 0.5s ease',
//             }}
//         >
//             <img style={{ height: '20px' }} src={SearchIconImage} alt="search-icon" className="icon search-icon" />
//             <input
//                 className="search-input-mobile"
//                 placeholder="Find cars, bikes, freelancers..."
//                 value={searchText}
//                 onChange={(e) => setSearchText(e.target.value)}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//             />
//             <img
//                 style={{ height: '20px' }}
//                 src={VoiceIconImage}
//                 alt="voice-icon"
//                 className="icon mic-icon-mobile"
//                 onClick={handleVoiceInput}
//             />
//         </div>
//     );
// };

// export default SearchComponent;
