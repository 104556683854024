import React from "react";
import "../css/footer.css";

const NewestListings = () => {
  const listings = [
    { name: "Fastrack classic watch", price: "₹1,000/Month", image: "https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp" },
    { name: "Creta automatic", price: "₹3,500/Day", image: "https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp" },
    { name: "Air Jordan 1 Retro High OG", price: "₹2,500/Month", image: "https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp" },
    { name: "Dell laptop", price: "₹2,200/Month", image: "https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp" },
  ];

  return (
    <div className="newest-listings-container">
      <div className="newest-listings-heading">Newest listings</div>
      <ul className="newest-listings-list">
        {listings.map((listing, index) => (
          <li key={index} className="listing-item">
       
            <img src={listing.image} alt={listing.name} className="listing-image" />
            
            <div className="listing-details">
              <span className="listing-name">{listing.name}</span>
              <span className="listing-price">{listing.price}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewestListings;
