

import React, { useState, useRef, useEffect } from 'react';
import ProductCard from './productCard';

const categories = [
  'All', 'Bikes', 'Cars', 'Electronics', 'Events', 'Freelancers', 'Furniture', 'Heavy Vehicles', 'Photoshoots', 'Property'
];

const products = [
  { image: 'https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp', name: 'Product 1', address: 'Location 1', price: "₹100/Day" },
  { image: 'https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp', name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { image: 'https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp', name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { image: 'https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp', name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { image: 'https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp', name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
];

const ProductList = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const productListRef = useRef(null);
  const firstProductCardRef = useRef(null); // Ref for measuring width of a ProductCard
  const [productCardWidth, setProductCardWidth] = useState(0);
  const [distTrav,setDistTrav]=useState(0)
  const [isMobileCarousel, setIsMobileCarousel] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
 

  useEffect(() => {
    // Set the width of a ProductCard based on the first product card's clientWidth
    if (firstProductCardRef.current) {
      setProductCardWidth(firstProductCardRef.current.clientWidth);
      // console.log(firstProductCardRef.current.clientWidth,(products.length*(productCardWidth+15)))
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobileCarousel(width >= 768 && width <= 991);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handlePreventScroll = (event) => {
      if (event.deltaX !== 0) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  
    const addScrollPreventListeners = () => {
      if (productListRef.current && window.innerWidth <= 991) {
        productListRef.current.addEventListener('wheel', handlePreventScroll, { passive: false });
        productListRef.current.addEventListener('touchmove', handlePreventScroll, { passive: false });
      }
    };
  
    const removeScrollPreventListeners = () => {
      if (productListRef.current) {
        productListRef.current.removeEventListener('wheel', handlePreventScroll);
        productListRef.current.removeEventListener('touchmove', handlePreventScroll);
      }
    };
  
    // Add listeners initially
    addScrollPreventListeners();
  
    const handleResize = () => {
      removeScrollPreventListeners();
      addScrollPreventListeners();
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      removeScrollPreventListeners();
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const scrollLeft = () => {
    if (activeIndex > 0) {
      const newIndex = activeIndex - 1;
      setActiveIndex(newIndex);
      const newDistTrav = newIndex * (productCardWidth + 15);
      setDistTrav(newDistTrav);
      productListRef.current.scrollTo({ left: newDistTrav, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (activeIndex < products.length - 1) {
      const newIndex = activeIndex + 1;
      setActiveIndex(newIndex);
      console.log(productCardWidth)
      const newDistTrav = newIndex * (productCardWidth + 15);
      setDistTrav(newDistTrav);
      productListRef.current.scrollTo({ left: newDistTrav, behavior: 'smooth' });
    }
  };


  const handleScrollLeft = () => {
    if (activeIndex > 0) {
      const newIndex = activeIndex - 1;
      setActiveIndex(newIndex);
      const newDistTrav = newIndex * (productCardWidth + 30);
      setDistTrav(newDistTrav);
      productListRef.current.scrollTo({ left: newDistTrav, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (activeIndex < products.length - 1) {
      const newIndex = activeIndex + 1;
      setActiveIndex(newIndex);
      const newDistTrav = newIndex * (productCardWidth + 30);
      setDistTrav(newDistTrav);
      productListRef.current.scrollTo({ left: newDistTrav, behavior: 'smooth' });
    }
  };

  return (
    <div className='product-Supreme'>
      <div className="product-list-container">
        <h1>Explore Featured Listings</h1>

        <div className="category-buttons">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${activeCategory === category ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="product-list-wrapper">
          <div className="product-list" ref={productListRef}>
            {products.map((product, index) => (
              <ProductCard
                key={index}
                ref={index === 0 ? firstProductCardRef : null} // Attach ref to the first ProductCard
                // image={product.image}
                images={[product.image,product.image,'https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp']}
                name={product.name}
                address={product.address}
                price={product.price}
              />
            ))}
          </div>
        </div>

{!isMobileCarousel?(
  <div className='arrow-btn-cont'>
          <button className="arrow-btn left" onClick={scrollLeft} style={{color:activeIndex===0 ? '#d4e4e4':'black',backgroundColor:activeIndex===0 ? '#e5f4f4':'#ddecf7'}} disabled={activeIndex===0}>&larr;</button>
          <button className="arrow-btn right" onClick={scrollRight} style={{color:activeIndex===(products.length-1) ? '#d4e4e4':'black',backgroundColor:activeIndex===(products.length-1) ? '#e5f4f4':'#ddecf7'}} disabled={distTrav===(products.length-1)}>&rarr;</button>
        </div>
):(

        <div className="carousel-navigator">
      
      <div className="dots">
        {products.slice(0, -1).map((_, index) => (
          <span
            key={index}
            className={`dot ${index === activeIndex ? 'active' : ''}`}
          ></span>
        ))}
      </div>
      <div className='arrow-btn-cont'>
          <button className="arrow-btn left" onClick={handleScrollLeft} style={{color:activeIndex===0 ? '#d4e4e4':'black',backgroundColor:activeIndex===0 ? '#e5f4f4':'#ddecf7'}} disabled={activeIndex===0}>&larr;</button>
          <button className="arrow-btn right" onClick={handleScrollRight} style={{color:activeIndex===(products.length-1) ? '#d4e4e4':'black',backgroundColor:activeIndex===(products.length-1) ? '#e5f4f4':'#ddecf7'}} disabled={distTrav===(products.length-1)}>&rarr;</button>
        </div>
    </div>
)}
      </div>
    </div>
  );
};

export default ProductList;
