import React, { useEffect, useState } from "react";
import "../css/imageForProductPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";

const  SuggestionImageCom=({images})=> {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  


    const handlePrevClick = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    };
  
    const handleNextClick = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    return (
      <div className="prod-page-img-sub-cont">
        <button
          className="Suggest-Prod-arrow-btn left"
          style={{ display: currentImageIndex === 0 ? 'none' : 'flex' }}
          onClick={handlePrevClick}
        >
          &larr;
        </button>
        <div className="slider-wrapper">
          <div
            className="image-slider"
            style={{
              transform: `translateX(-${currentImageIndex * 100}%)`,
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`image-${index}`}
                className={`Suggest-Prod-slider-image `}
                
              />
            ))}
          </div>
        </div>
        <button
          className="Suggest-Prod-arrow-btn right"
          onClick={handleNextClick}
          style={{ display: currentImageIndex === images.length - 1 ? 'none' : 'flex' }}
        >
          &rarr;
        </button>

        <div className="carousel-dots-cont">
            <div className="carousel-dots">
              {images.map((_, index) => {
                const distance = Math.abs(index - currentImageIndex);
                const size = Math.max(10 - distance * 2, 4);

                return (
                  <span
                    key={index}
                    className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  ></span>
                );
              })}
            </div>
            </div>
  
       
  
        
  
      </div>
    );
  };
  
  export default SuggestionImageCom;
  
