// import React from 'react';
// import '../css/discovermore.css';

// const products = [
//   { name: 'Air conditioning', image: 'https://goleezy.com/wp-content/uploads/2023/03/woman-with-wall-mounted-air-conditioning-1080x675-1.jpg.webp' },
//   { name: 'BMW', image: 'https://goleezy.com/wp-content/uploads/2024/06/bmw-m4-4k-hd-image-wallpaper-preview.jpg.webp' },
//   { name: 'JCB', image: 'https://goleezy.com/wp-content/uploads/2023/03/emag-jcb-cover-imgs.jpg.webp' },
//   { name: 'Jordan', image: 'https://goleezy.com/wp-content/uploads/2023/03/air-jordan-1-university-blue-release-info-02.webp' },
//   { name: 'Iphone', image: 'https://goleezy.com/wp-content/uploads/2023/06/iphone-14-pro-max-colors.png.webp' },
//   { name: 'SUVs', image: 'https://goleezy.com/wp-content/uploads/2024/06/download-1.jpg' },
//   // Add more items with image URLs as needed
// ];

// const DiscoverMore = () => {
//   return (
//     <div className='discover-Supreme'>
//     <div className="discover-more-container">
//       <div className="header">
//         <h2>Discover More</h2>
//         <button className="view-more">View More</button>
//       </div>
//       <div className="product-grid-discover">
//         {products.map((product, index) => (
//           <div className="product-card-discover" key={index}>
//             <div className='Product-image'>
//             <img src={product.image} alt={product.name} className="product-image-discover" />
//             </div>
//             <div className="product-info-discover">
//             <p>{product.name}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     </div>
//   );
// };

// export default DiscoverMore;






import React from 'react';
import '../css/discovermore.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const products = [
  { name: 'Air conditioning', image: 'https://goleezy.com/wp-content/uploads/2023/03/woman-with-wall-mounted-air-conditioning-1080x675-1.jpg.webp' },
  { name: 'BMW', image: 'https://goleezy.com/wp-content/uploads/2024/06/bmw-m4-4k-hd-image-wallpaper-preview.jpg.webp' },
  { name: 'JCB', image: 'https://goleezy.com/wp-content/uploads/2023/03/emag-jcb-cover-imgs.jpg.webp' },
  { name: 'Jordan', image: 'https://goleezy.com/wp-content/uploads/2023/03/air-jordan-1-university-blue-release-info-02.webp' },
  { name: 'Iphone', image: 'https://goleezy.com/wp-content/uploads/2023/06/iphone-14-pro-max-colors.png.webp' },
  { name: 'SUVs', image: 'https://goleezy.com/wp-content/uploads/2024/06/download-1.jpg' },
];

const DiscoverMore = () => {
  return (
    <div className='discover-Supreme'>
      <div className="discover-more-container">
        <div className="header">
          <h2>Discover more</h2>
          <button className="view-more">View More</button>
        </div>
        <div className="product-grid-discover">
          {products.map((product, index) => (
            <div className="product-card-discover" key={index}>
              <div className='Product-image'>
                <img src={product.image} alt={product.name} className="product-image-discover" />
                <div className="overlay"> 
                </div>
                <button className="circular-button"><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '20px' }} /></button>
              </div>
              <div className="product-info-discover">
                <p>{product.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscoverMore;
