
import React, { useState } from 'react';
import '../css/favoriteCard.css';
import '../css/productCard.css';
import Heart from "../Assets/heart-svgrepo-com.svg";
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FavoritePageCard = ({ data }) => {
    const [currentImage, setCurrentImage] = useState(0);

    const handlePrevImage = () => {
        setCurrentImage((prev) => (prev === 0 ? data.images.length - 1 : prev - 1));
    };

    const handleNextImage = () => {
        setCurrentImage((prev) => (prev === data.images.length - 1 ? 0 : prev + 1));
    };

    

    const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonType) => {
    setActiveButton((prev) => (prev === buttonType ? null : buttonType));
  };

    return (
        <div className="favorite-card-container">
            <div className="favorite-card-image-section">
            <button className="favorite-card-arrow-btn favorite-left" style={{display:currentImage===0?'none':'flex'}} onClick={handlePrevImage}>
                 &larr;
             </button>
             {/* <div className="slider-wrapper">
          <div
            className="image-slider"
            style={{
              transform: `translateX(-${currentImage * 100}%)`,
            }}
          >
             {data.images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`image-${index}`}
                    className="favorite-card-image"
                />
             ))}
             </div>
             </div> */}
             <div className="slider-wrapper">
  <div
    className="image-slider"
    style={{
      transform: `translateX(-${currentImage * 100}%)`,
    }}
  >
    {data.images.map((image, index) => (
      <div className="favorite-image-container" key={index}>
        <img
          src={image}
          alt={`image-${index}`}
          className="favorite-card-image"
        />
      </div>
    ))}
  </div>
</div>

                <button className="favorite-card-arrow-btn favorite-right" style={{display:currentImage===(data.images.length - 1)?'none':'flex'}} onClick={handleNextImage}>
                  &rarr;
               </button>

                <div className="favorite-carousel-dots-cont">
  <div className="favorite-carousel-dots">
    {data.images.map((_, index) => {
      const distance = Math.abs(index - currentImage);
      const size = Math.max(10 - distance * 2, 4); // Decrease size with distance but keep minimum size

      return (
        <span
          key={index}
          className={`favorite-carousel-dot ${index === currentImage ? 'active' : ''}`}
          style={{
            width: `${size}px`,
            height: `${size}px`,
          }}
        ></span>
      );
    })}
  </div>
</div>
            </div>
            <div className='favorite-card-right-side-sect'>
            <div className="favorite-card-info-section">
                <h3 className="favorite-card-title">{data.name}</h3>
                <p className="favorite-card-location">{data.location}</p>
                <p className="favorite-card-price">{data.price}/Day</p>
                <span className="favorite-card-category">{data.category}</span>
            </div>
            <div className="favorite-card-footer">

            <div className='favorite-card-pf'>
            
                        <div className='fav-icons-br'>
                        
                         <FontAwesomeIcon className="fav-icons" icon={faUser} />
                        </div>
                        <span className='fav-icon-user-sp'>{data.user}</span>
                      

            </div>
                
            <div className="product-actions" style={{borderTop:'none'}}>

            
        <div className="actions">
   
          
        <button
        className={`compare ${activeButton === "compare" ? "active" : ""}`}
        onClick={() => handleButtonClick("compare")}
      >
        <img src={Heart} alt="Compare" />
        <span className="tooltip" >Add to Compare</span>
      </button>

      <button
        className={`like ${activeButton === "like" ? "active" : ""}`}
        onClick={() => handleButtonClick("like")}
      >
        <img src={Heart} alt="Like" />
        <span className="tooltip" >Add to Favorites</span>
      </button>
        </div>
        
        {/* <p className="views-count">277 views</p> */}
      </div>
                
            </div>
            </div>
        </div>
    );
};

export default FavoritePageCard;
