
import React, { useState,useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import "../css/profile.css";
import AddNew from "./ProfileTabs/AddNew";
import Orders from "./ProfileTabs/Orders";
import MyAds from "./ProfileTabs/MyAds";
import Favorites from "./ProfileTabs/Favorites";
import Messages from "./ProfileTabs/Messages";
import MyOrders from "./ProfileTabs/MyOrders";
import Settings from "./ProfileTabs/Settings";
import ProfileHeading from "../components/ProfileHeading";

const Profile = () => {
    const [activeTab, setActiveTab] = useState("Add New");
    const [isButton,setIsButton]=useState(true)

    useEffect(() => {
        switch (activeTab) {
            case "Add New":
            case "Favorites":
            case "Messages":
            case "Settings":
                setIsButton(true);
                break;
            default:
                setIsButton(false);
                break;
        }
    }, [activeTab]);

    const renderContent = () => {
        switch (activeTab) {
            case "Add New":
                return <AddNew />;
            
            case "Orders":
                return <Orders />;
            case "My Ads":
                return <MyAds />;
            case "Favorites":
                return <Favorites />;
            case "Messages":
                return <Messages />;
            case "My Orders":
                return <MyOrders />;
            case "Settings":
                return <Settings />;
            default:
                return null;
        }
    };

    return (
        <>
            <Navbar />
            <div className="container-5">
                <div className="scrollable-tabs-container">
                    <div className="tabs">
                        {["Add New", "Orders", "My Ads", "Favorites", "Messages", "My Orders", "Settings"].map((tab) => (
                            <button 
                                key={tab} 
                                className={activeTab === tab ? "active-tab" : ""} 
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="tab-content" >
                    {activeTab!=="My Ads"?(
                        <ProfileHeading activeTab={activeTab} isButton={isButton}/>
                    )
                    :
                    null
                    }
                     
                    {renderContent()}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Profile;
