

import React, { useState,useEffect } from "react";
import "../../css/MyOrders.css";
import "../../css/Order.css";
import { faUser} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faMobile, faPaperPlane,faAngleDown  } from '@fortawesome/free-solid-svg-icons';
const OrderList = [
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Completed" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Processing" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Cancelled" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Cancelled" },
  { title: "30 days showcase",userName:'testUser', order: "#11182", created: "11/07/2024", price: "₹99.00", paymentMethod: "PhonePe Payment Solutions", status: "Failed" },
  { title: "7 days showcase",userName:'testUser', order: "#10294", created: "16/06/2024", price: "₹49.00", paymentMethod: "PhonePe Payment Solutions", status: "Failed" },
  { title: "30 days showcase",userName:'testUser', order: "#11687", created: "13/10/2024", price: "₹0.00", status: "Completed" },
  { title: "30 days showcase",userName:'testUser', order: "#11189", created: "11/07/2024", price: "₹99.00", paymentMethod: "PhonePe Payment Solutions", status: "Completed" },
  { title: "7 days showcase",userName:'testUser', order: "#10296", created: "16/06/2024", price: "₹49.00", paymentMethod: "PhonePe Payment Solutions", status: "Pending" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Completed" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Processing" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Cancelled" },
  { title: "30 days showcase",userName:'testUser', order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Cancelled" },
  { title: "30 days showcase",userName:'testUser', order: "#11182", created: "11/07/2024", price: "₹99.00", paymentMethod: "PhonePe Payment Solutions", status: "Failed" },
  { title: "7 days showcase",userName:'testUser', order: "#10294", created: "16/06/2024", price: "₹49.00", paymentMethod: "PhonePe Payment Solutions", status: "Failed" },
  { title: "30 days showcase",userName:'testUser', order: "#11687", created: "13/10/2024", price: "₹0.00", status: "Completed" },

];

const Orders = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [dropdownStates, setDropdownStates] = useState(OrderList.map(() => false)); // Array for dropdown visibility
  const [selectedOptions, setSelectedOptions] = useState(OrderList.map(() => "Actions")); // Array for selected options

  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const resultsPerPage = 10; // Number of results per page

  const options = ["Completed", "Pending", "On hold", "Refunded", "Failed", "Delete"];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [ currentPage]);

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option);
//     setDropdownVisible(false); // Close the dropdown after selection
//   };

const toggleDropdown = (index, isVisible) => {
    const updatedDropdownStates = [...dropdownStates];
    updatedDropdownStates[index] = isVisible;
    setDropdownStates(updatedDropdownStates);
  };

  const handleOptionSelect = (index, option) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = option;
    setSelectedOptions(updatedOptions);

    const updatedDropdownStates = [...dropdownStates];
    updatedDropdownStates[index] = false; // Close the dropdown after selection
    setDropdownStates(updatedDropdownStates);
  };

  // Function to handle the tab selection
  const handleTabClick = (status) => {
    setSelectedStatus(status);
  };

  // // Filter orders based on the selected status
  // const filteredOrders = selectedStatus === "All"
  //   ? OrderList
  //   : OrderList.filter(order => order.status === selectedStatus);

    // Filter orders based on the selected status
  const filteredOrders = selectedStatus === "All"
  ? OrderList
  : OrderList.filter(order => order.status === selectedStatus);

// Calculate total pages
const totalPages = Math.ceil(filteredOrders.length / resultsPerPage);

// Get the current page's data
const paginatedOrders = filteredOrders.slice(
  (currentPage - 1) * resultsPerPage,
  currentPage * resultsPerPage
);

const handlePageChange = (page) => {
  if (page > 0 && page <= totalPages) {
    setCurrentPage(page);
  }
};

  return (
    <div className="my-order-container">
      <div className="my-order-tab-container order-tab-container">
        <button
          className={`my-order-tab ${selectedStatus === "All" ? "active" : ""}`}
          onClick={() => handleTabClick("All")}
        >
          All <span className='my-order-tab-span'>{OrderList.length}</span>
        </button>
        <button
          className={`my-order-tab ${selectedStatus === "Completed" ? "active" : ""}`}
          onClick={() => handleTabClick("Completed")}
        >
          Completed <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Completed").length}</span>
        </button>
        <button
          className={`my-order-tab ${selectedStatus === "Processing" ? "active" : ""}`}
          onClick={() => handleTabClick("Processing")}
        >
          Processing <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Processing").length}</span>
        </button>
        <button
          className={`my-order-tab ${selectedStatus === "Pending" ? "active" : ""}`}
          onClick={() => handleTabClick("Pending")}
        >
          Pending <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Pending").length}</span>
        </button>
        <button
          className={`my-order-tab ${selectedStatus === "Cancelled" ? "active" : ""}`}
          onClick={() => handleTabClick("Cancelled")}
        >
          Cancelled <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Cancelled").length}</span>
        </button>
        <button
          className={`my-order-tab ${selectedStatus === "Failed" ? "active" : ""}`}
          onClick={() => handleTabClick("Failed")}
        >
          Failed <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Failed").length}</span>
        </button>

        <input className="order-Input" placeholder="Enter order id,  username or email"></input>
      </div>

      <div className="my-order-table-container">
        <div className="order-row" id="offInMobile">
          <div className="my-order-info" id="my-order-gen">
            General Info
          </div>
          <div className="my-order-status for-pc-mob" id="my-order-stat">
            Contact
          </div>
          <div className="my-order-status for-pc " id="my-order-stat">
            Status
          </div>
          <div className="my-order-status for-pc-mob" id="my-order-stat">
            Actions
          </div>
        </div>
        
        {paginatedOrders.map((order, index) => (
          <div className="order-row" key={index}>
            <div className="my-order-info">
              <h3 className="my-order-title">
                {order.title} <span className="my-order-title-span">({order.order})</span>
              </h3>
              <p className="my-order-date-text order-img-span-cont"> <span className="order-img-span"><FontAwesomeIcon icon={faUser} className="inner" /></span> <span className="my-order-date-text-span">{order.userName}</span></p>
              <p className="my-order-date-text">Created: <span className="my-order-date-text-span">{order.created}</span></p>
              <span className={`my-order-status-badge for-tab ${order.status.toLowerCase()}`}>
                {order.status}
              </span>
              <p className="my-order-price-text order-price-text">{order.price}</p>
              {order.paymentMethod && <span className="my-order-payment-method">via {order.paymentMethod}</span>}
            </div>
            <div className="order-contacts">
                <span className="order-cont-ico-sp"><FontAwesomeIcon icon={faAt} className="contact-icon" /></span>
                <span className="order-cont-ico-sp"><FontAwesomeIcon icon={faMobile} className="contact-icon" /></span>
                <span className="order-cont-ico-sp"><FontAwesomeIcon icon={faPaperPlane} className="contact-icon" /></span>
            </div>
            <div className="my-order-status for-pc">
              <span className={`my-order-status-badge ${order.status.toLowerCase()}`}>
                {order.status}
              </span>
            </div>
            <div
              className="order-dropdown-container"
              onMouseEnter={() => toggleDropdown(index, true)}
              onMouseLeave={() => toggleDropdown(index, false)}
            >
              <button className="order-dropdown-button">
                {selectedOptions[index]} <FontAwesomeIcon icon={faAngleDown} />
              </button>

              {dropdownStates[index] && (
                <div className="order-dropdown-menu">
                  {options.map((option, optionIndex) => (
                    <div
                      key={optionIndex}
                      className="order-dropdown-item"
                      onClick={() => handleOptionSelect(index, option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
           
          </div>
        ))}
      </div>

      {/* Result Counter */}
      <div className="order-result-cont">
      <div className="my-order-result-counter order-result-counter">
        Showing <span className="my-order-result-counter-span">{((currentPage-1)*10)+1}</span> to <span className="my-order-result-counter-span">{((filteredOrders.length - (resultsPerPage*currentPage)) > 0) ?(resultsPerPage*currentPage) : Math.abs(filteredOrders.length - (resultsPerPage*currentPage))}</span> of <span className="my-order-result-counter-span">{filteredOrders.length}</span> results
      </div>
      <div className="order-pagination-container">
        <button onClick={() => handlePageChange(currentPage - 1)} className={`${currentPage<=1?"disabled":""}`}>←</button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={() => handlePageChange(currentPage + 1)} className={`${currentPage>=totalPages?"disabled":""}`}>→</button>
      </div>
      </div>
    </div>
  );
};

export default Orders;


