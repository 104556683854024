import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import styles
import "../css/corousal.css";
import firstCaro from '../Assets/firstCaro.webp'
import secondCaro from '../Assets/secondCaro.webp'
import thirdCaro from '../Assets/thirdCaro.webp'

const CarouselComponent = () => {
  return (
    <Carousel 
      infiniteLoop={true} 
      autoPlay={true} 
      interval={3000} 
      showArrows={false} // Hide the left and right arrows
      showThumbs={false} // Hide the thumbnails
      showStatus={false} // Hide the "1 of 3" status indicator
    >
      <div>
        <img src={thirdCaro} alt="Slide 1" />
      </div>
      <div>
        <img src={firstCaro} alt="Slide 2" />
      </div>
      <div>
        <img src={secondCaro} alt="Slide 3" />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
