import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./pages/Home";
import Auth from './pages/Auth';
import PrivateRoute from './components/authmiddle/PrivateRoute';
import useAuth from './components/authmiddle/useAuth';
import ForgotPass from './pages/ForgotPass';
import Productpage from "./pages/productpage";
import Profile from "./pages/profile";
import Package from "./pages/package"
import useStore from './components/authmiddle/store';

function App() {
  // const { authenticated } = useAuth();
  const {  isAuthenticated } = useStore();

  return (
    <>
    <Routes>
      {/* Private route for Home, only accessible if authenticated */}
      <Route path="/" element={<Home/>} />
      <Route path="/product" element={<Productpage/>} />
      <Route element={<PrivateRoute />}>
        <Route path="/profile" element={<Profile />} />
      </Route>
      <Route path="/package" element={<Package/>} />
      
      {/* Public routes for Login and Register, but hide them if authenticated */}
      <Route path="/auth" element={isAuthenticated? <Navigate to="/" /> : <Auth />} />
      <Route path="/forgot-pass" element={isAuthenticated? <Navigate to="/" /> : <ForgotPass />} />
      
     

     
     
      {/* Redirect any unknown routes to Home */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    </>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
