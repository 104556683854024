import React, { useState, useEffect } from 'react';
import Navbar from "./navbar";
import "../css/home.css";
import Corousal_Container from "./corousal";
import CategoryCard from "./categorycard";
import categories from "./category";
import ProductList from './productlist';
import "../css/categorypage.css";
import Tutorial from './tutorial';
import DiscoverMore from './discovermore';
import Footer from "./footer"
export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  // Detect screen size on initial load and resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Navbar />
      
      {/* Render categories above carousel on larger screens */}
      {!isMobile && (
        <div className="category-page">
          <div className="category-grid">
            {categories.map((category, index) => (
              <CategoryCard key={index} name={category.name} image={category.image} />
            ))}
          </div>
        </div>
      )}

      <Corousal_Container />
      
      {/* Render categories below carousel on mobile */}
      {isMobile && (
        <div className="category-page">
          <h1>What are you looking to rent?</h1>
          <div className="category-grid">
            {categories.map((category, index) => (
              <CategoryCard key={index} name={category.name} image={category.image} />
            ))}
          </div>
        </div>
      )}

      <ProductList />
      <Tutorial />
      <DiscoverMore />
      <Footer />
    </>
  );
}
