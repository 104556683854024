// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .productPageCard{
    width:100%;
    padding: 30px;
    box-shadow: none !important;
}

.productPageCard-info{
  
    padding:0 !important;
} */`, "",{"version":3,"sources":["webpack://./src/css/productPageCard.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG","sourcesContent":["/* .productPageCard{\n    width:100%;\n    padding: 30px;\n    box-shadow: none !important;\n}\n\n.productPageCard-info{\n  \n    padding:0 !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
