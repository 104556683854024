// categories.js
import clothes from "../Assets/clothes-clothing-garment-svgrepo-com (2).svg";
import laptop from "../Assets/computer-svgrepo-com.svg";
import shoes from "../Assets/shoes.png";
import shops from "../Assets/shop.png";
import bikes from "../Assets/motorbike.png";
import cars from "../Assets/car-svgrepo-com.svg";
import electronics from "../Assets/washing-machine-one-svgrepo-com.svg";
import freelancer from "../Assets/freelancer.svg";
import furniture from "../Assets/furniture-house-living-9-svgrepo-com.svg";
import photoshoot from "../Assets/camera-svgrepo-com.svg";
import property from "../Assets/building-one-svgrepo-com.svg";
import event from "../Assets/calender.svg";
import heavyVehicles from "../Assets/trucks.svg";
import homeAppliances from "../Assets/toasting.svg";
import games from "../Assets/videoGame.svg";
import jewellery from "../Assets/jwellery.svg";
import books from "../Assets/books-book-svgrepo-com.svg";
import others from "../Assets/other.svg";

const categories = [
  { name: "Clothes", image: clothes },
  { name: "Laptops", image: laptop },
  { name: "Shoes", image: shoes },
  { name: "Shops", image: shops },
  { name: "Bikes", image: bikes },
  { name: "Cars", image: cars },
  { name: "Electronics", image: electronics },
  { name: "Freelancers", image: freelancer },
  { name: "Furniture", image: furniture },
  { name: "Photoshoots", image: photoshoot },
  { name: "Properties", image: property },
  { name: "Events", image: event },
  { name: "Heavy Vehicles", image: heavyVehicles },
  { name: "Home Appliances", image: homeAppliances },
  { name: "Games", image: games },
  { name: "Jewellery", image: jewellery },
  { name: "Books", image: books },
  { name: "Others", image: others },
];

export default categories;
