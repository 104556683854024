// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
    border: 5px solid white; /* Add a border around the carousel */
    border-radius: 15px; /* Round the corners of the border */
    overflow: hidden; /* Ensure the images do not overflow the border radius */
    /* Create space on the left and right without padding */
    width: 20%;
    margin-left: 10px;
}
.carousel-root{
    margin-left: 2vw;
    margin-right: 2vw;
}
.carousel img {
    width: 100%; /* Ensure images take full width */
    height: 19vh; /* Maintain a fixed height */
    object-fit: cover; /* Ensure the aspect ratio is maintained */
}

/* For tablets (width between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .carousel img {
        height: 25vh; /* Increase height for tablets */
    }
}

/* For desktops (width above 1024px) */
@media (min-width: 1024px) {
    .carousel img {
        height: 30vh; /* Increase height for desktops */
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/corousal.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,qCAAqC;IAC9D,mBAAmB,EAAE,oCAAoC;IACzD,gBAAgB,EAAE,wDAAwD;IAC1E,uDAAuD;IACvD,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,WAAW,EAAE,kCAAkC;IAC/C,YAAY,EAAE,4BAA4B;IAC1C,iBAAiB,EAAE,0CAA0C;AACjE;;AAEA,iDAAiD;AACjD;IACI;QACI,YAAY,EAAE,gCAAgC;IAClD;AACJ;;AAEA,sCAAsC;AACtC;IACI;QACI,YAAY,EAAE,iCAAiC;IACnD;AACJ","sourcesContent":[".carousel {\n    border: 5px solid white; /* Add a border around the carousel */\n    border-radius: 15px; /* Round the corners of the border */\n    overflow: hidden; /* Ensure the images do not overflow the border radius */\n    /* Create space on the left and right without padding */\n    width: 20%;\n    margin-left: 10px;\n}\n.carousel-root{\n    margin-left: 2vw;\n    margin-right: 2vw;\n}\n.carousel img {\n    width: 100%; /* Ensure images take full width */\n    height: 19vh; /* Maintain a fixed height */\n    object-fit: cover; /* Ensure the aspect ratio is maintained */\n}\n\n/* For tablets (width between 768px and 1024px) */\n@media (min-width: 768px) and (max-width: 1024px) {\n    .carousel img {\n        height: 25vh; /* Increase height for tablets */\n    }\n}\n\n/* For desktops (width above 1024px) */\n@media (min-width: 1024px) {\n    .carousel img {\n        height: 30vh; /* Increase height for desktops */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
