import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Autocomplete from 'react-google-autocomplete';
import "../../css/AddNew.css";

const AddNew = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [category, setCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [autoCompleteEnabled, setAutoCompleteEnabled] = useState(false);

  const categories = [
    "Bikes",
    "Books",
    "Cars",
    "Clothes",
    "Electronics",
    "Events",
    "Freelancers",
    "Furniture",
    "Games",
    "Heavy Vehicles",
    "Jewellery",
    "Laptops",
    "Others",
    "Photoshoots",
    "Property",
    "Shoes",
    "Shops",
  ];

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const handlePostAd = () => {
    console.log({
      adName: document.getElementById("adName").value,
      category,
      description: editorState.getCurrentContent().getPlainText(),
      location,
      autoCompleteEnabled,
    });
  };

  const filteredCategories = categories.filter((cat) =>
    cat.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="post-ad-page">
      <h1>General Info</h1>

      {/* Ad Name */}
      <label htmlFor="adName">Ad Name*</label>
      <input id="adName" type="text" className='inputbartext' placeholder="Enter Ad Name" />

      {/* Category with Search */}
      <label htmlFor="category">Category</label>
      <select
        id="category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      >
        <option value="">Select a category</option>
        {filteredCategories.map((cat, index) => (
          <option key={index} value={cat}>
            {cat}
          </option>
        ))}
      </select>

      {/* Description */}
      <label>Description</label>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleEditorChange}
      />

      {/* Address */}
      <label>Address</label>
      <Autocomplete
        apiKey="YOUR_GOOGLE_API_KEY"
        onPlaceSelected={(place) => setLocation(place.formatted_address)}
        placeholder="Enter location"
        className='inputbartext'
      />
      <div className="checkbox-container">
        <input
          type="checkbox"
          checked={autoCompleteEnabled}
          onChange={() => setAutoCompleteEnabled(!autoCompleteEnabled)}
          className='location-checkbox'
        />
        <label>Autocomplete address when marker position is changed</label>
      </div>

      {/* Map */}
      <div className="map-div" style={{backgroundColor:'white'}}>
        <iframe
          width="100%"
          src="https://www.google.com/maps/embed"
          title="Google Map"
        ></iframe>
      </div>

      {/* Post Ad */}
      <button className='addNewBtn' onClick={handlePostAd}>Post Your Ad</button>
    </div>
  );
};

export default AddNew;
