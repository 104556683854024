import React, { useState } from 'react';
import '../css/quickViewCard.css';
import Eye from '../Assets/eye-svgrepo-com.svg';


const QuickViewCard = ({ data, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { images, name, category, address, price, views, timeAgo } = data;

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className='quick-view-card-overlay'>
    <div className="quick-view-card">
      <div className="quick-view-image-section">
        <button
          className="quick-view-arrow-btn left"
          onClick={handlePrevClick}
          style={{ display: images.length > 1 ? 'flex' : 'none' }}
        >
          &larr;
        </button>
        <img
          src={images[currentImageIndex]}
          alt={name}
          className="quick-view-image"
        />
        <button
          className="quick-view-arrow-btn right"
          onClick={handleNextClick}
          style={{ display: images.length > 1 ? 'flex' : 'none' }}
        >
          &rarr;
        </button>

        <div className="quick-view-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`quick-view-dot ${
                index === currentImageIndex ? 'active' : ''
              }`}
            ></span>
          ))}
        </div>
      </div>

      <div className="quick-view-info-section">
        <button className="quick-view-close-btn" onClick={onClose}>
          X
        </button>
        <p className="quick-view-time">{timeAgo}</p>
        <h3 className="quick-view-name">{name}</h3>
        <p className="quick-view-category">{category}</p>
        <p className="quick-view-address">{address}</p>
        <p className="quick-view-price">{price}</p>
        <p className="quick-view-views">
           {views} Views
        </p>
        <button className="quick-view-more-btn">View more</button>
      </div>
    </div>
    </div>
  );
};

export default QuickViewCard;
