// import React, { useState } from 'react';
// import '../css/SuggestionProductPageSlider.css';
// import Heart from "../Assets/heart-svgrepo-com.svg";
// import Eye from "../Assets/eye-svgrepo-com.svg";
// import QuickViewCard from '../components/QuickViewCard';


// const products = [
//   { images: ['https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp','https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp','https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp'], name: 'Product 1', address: 'Location 1', price: "100/Day" },
//   { images: ['https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "150/Day" },
//   { images: ['https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "150/Day" },
//   { images: ['https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "150/Day" },
//   { images: ['https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "150/Day" },
// ];

// export default function SuggestionProductPageSlider() {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [isQuickViewVisible, setIsQuickViewVisible] = useState(false);

//   const handlePrevClick = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex === 0 ? products.images.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNextClick = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex === products.images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const openQuickView = () => {
//     setIsQuickViewVisible(true);
//   };

//   const closeQuickView = () => {
//     setIsQuickViewVisible(false);
//   };

//   return (
//     <>
//       <div className="product-card" >
//         <div className="carousel-product-card">
//           <button
//             className="product-card-arrow-btn left"
//             style={{ display: currentImageIndex === 0 ? 'none' : 'flex' }}
//             onClick={handlePrevClick}
//           >
//             &larr;
//           </button>
//           <img src={product.images[currentImageIndex]} alt={product.name} className="product-image" />
//           <button
//             className="product-card-arrow-btn right"
//             style={{ display: currentImageIndex === product.images.length - 1 ? 'none' : 'flex' }}
//             onClick={handleNextClick}
//           >
//             &rarr;
//           </button>

//           <div className="carousel-dots-cont">
//             <div className="carousel-dots">
//               {products.images.map((_, index) => {
//                 const distance = Math.abs(index - currentImageIndex);
//                 const size = Math.max(10 - distance * 2, 4);

//                 return (
//                   <span
//                     key={index}
//                     className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
//                     style={{
//                       width: `${size}px`,
//                       height: `${size}px`,
//                     }}
//                   ></span>
//                 );
//               })}
//             </div>
//           </div>
//         </div>

//         <div className="product-info">
//           <h3 className="product-name">{products.name}</h3>
//           <p className="product-address">{products.address}</p>
//           <p className="product-price">{products.price}</p>
//         </div>

//         <div className="product-actions">
//           <div className="actions">
//             <button className="quick-view" onClick={openQuickView}>
//               <img src={Eye} alt="View" />
//               <span className="tooltip">Quick View</span>
//             </button>
            
//             <button className="compare">
//               <img src={Heart} alt="Compare" />
//               <span className="tooltip">Add to Compare</span>
//             </button>
            
//             <button className="like">
//               <img src={Heart} alt="Like" />
//               <span className="tooltip">Add to Favorites</span>
//             </button>
//           </div>
          
//           <p className="views-count">277 views</p>
//         </div>
//       </div>

     
//     </>
//   );
// };


import React, { useState,useEffect } from 'react';
import '../css/SuggestionProductPageSlider.css';
import Heart from "../Assets/heart-svgrepo-com.svg";
import Eye from "../Assets/eye-svgrepo-com.svg";
import QuickViewCard from '../components/QuickViewCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import ImageForProductPage from './ImageForProductPage';
import SuggestionImageCom from './SuggestionImageCom';


const products = [
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp','https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp','https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp'], name: 'Product 1', address: 'Location 1', price: "100/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp','https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp','https://goleezy.com/wp-content/uploads/2024/07/image-360x240.jpg.webp'], name: 'Product 1', address: 'Location 1', price: "100/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/06/FF670F32-CA3A-4F63-B6CC-44B9382A54A7-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/IMG_3323-720x640-1-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/7FF2C945-3E5F-4F43-AAD4-5445B28DFC4A-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },
  { images: ['https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp','https://goleezy.com/wp-content/uploads/2024/07/E2AA389B-B80E-4836-A6F7-831A7016BBE3-360x240.jpeg.webp'], name: 'Product 2', address: 'Location 2', price: "₹150/Day" },

];

export default function SuggestionProductPageSlider({headingMsg,buttonMsg,buttonMobMsg}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [Val, setVal] = useState(100); // Initial value of Val

  // Update Val based on screen width
  const updateVal = () => {
    const width = window.innerWidth;
    if (width >= 1050) {
      setVal(27); // Full width for PC
    } else if (width >= 768 && width < 1050) {
      setVal(32); // Half width for tablets
    } else {
      setVal(100); // Full width for smaller devices
    }
  };

  useEffect(() => {
    updateVal(); // Set initial Val based on current screen width
    window.addEventListener("resize", updateVal); // Add resize listener
    return () => {
      window.removeEventListener("resize", updateVal); // Clean up listener
    };
  }, []);

  // Handlers for navigation
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === products.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div className='Suggest-Prod-Supreme'>


      <div className="Suggest-Prod-Card-listing-controls">

                     <h3 className='Suggest-Prod-headingOfLi'>{headingMsg}</h3>
                     <div className='Suggest-Prod-buttons-cont'>
                     <button className="Spec-btn " onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                         {buttonMsg}
                        </button>
                      <div className="Suggest-Prod-buttons">
                      
                        <button className=" btn prev" onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft}  />
                        </button>
                        <button className="btn next" onClick={handleNextClick} style={{backgroundColor:Val===50 ? (currentIndex+1===products.length-1?'#f4fcfc':'#DDECF7'):(currentIndex+1===products.length?'#f4fcfc':'#DDECF7') ,color:Val===50 ? (currentIndex+1===products.length-1?'#dce4e4':'#374B5C'):(currentIndex+1===products.length?'#dce4e4':'#374B5C') }}  disabled={Val===50 ? currentIndex+1===products.length-1:currentIndex+1===products.length }>
                          <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        </div>
                        </div>
                   
      </div>

      <div className="slider-wrapper">
        <div
          className="card-slider"
          style={{
            transform: `translateX(-${currentIndex * Val}%)`,
            display: "flex",
            transition: "transform 0.5s ease-in-out",
            gap:"20px"
          }}
        >
        {products.map((listing, index) => (
            <div
              key={index}
              className="product-card Suggest-Prod-Card "
              style={{
                flex:"0 0 auto"
              }}
            >
              {/* <img
                src={listing.images[0]}
                alt={listing.name}
                className="product-image"
              /> */}
              <SuggestionImageCom images={listing.images}/>
              
              <div
                className="product-info productPageCard-info"
                
              >
                <h3 className="product-name">{listing.name}</h3>
                <p className="product-address">{listing.address}</p>
                <p className="product-price">{listing.price}</p>
              </div>

              <div className="product-actions">
          <div className="actions">
            <button className="quick-view" >
              <img src={Eye} alt="View" />
              <span className="tooltip">Quick View</span>
            </button>
            
            <button className="compare">
              <img src={Heart} alt="Compare" />
              <span className="tooltip">Add to Compare</span>
            </button>
            
            <button className="like">
              <img src={Heart} alt="Like" />
              <span className="tooltip">Add to Favorites</span>
            </button>
          </div>
          
          <p className="views-count">277 views</p>
        </div>
            </div>
          ))}
        </div>
        </div>


        <div className='Suggest-Prod-buttons-cont-forMobile'>
                     <button className="Spec-btn " onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                         {buttonMobMsg}
                        </button>
                      <div className="Suggest-Prod-buttons">
                      
                        <button className=" btn prev" onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft}  />
                        </button>
                        <button className="btn next" onClick={handleNextClick} style={{backgroundColor:Val===50 ? (currentIndex+1===products.length-1?'#f4fcfc':'#DDECF7'):(currentIndex+1===products.length?'#f4fcfc':'#DDECF7') ,color:Val===50 ? (currentIndex+1===products.length-1?'#dce4e4':'#374B5C'):(currentIndex+1===products.length?'#dce4e4':'#374B5C') }}  disabled={Val===50 ? currentIndex+1===products.length-1:currentIndex+1===products.length }>
                          <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        </div>
          </div>

        

      </div>

     
    </>
  );
};