import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import useStore from './store';
import axios from 'axios';
import { io } from 'socket.io-client';

const PrivateRoute = () => {
  const { setIsAuthenticated, setUserId, socket, setSocket, isAuthenticated } = useStore();
  const [loading, setLoading] = useState(true); // Added loading state
  const navigate = useNavigate();

  useEffect(() => {
    let newSocket;

    const checkAuthentication = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/userdetails`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setIsAuthenticated(true);
          setUserId(response.data.email);

          if (!socket) {
            newSocket = io(`${process.env.REACT_APP_BACK_URL}`, {
              auth: {
                token: response.data.email,
              },
            });
           console.log(newSocket)
            setSocket(newSocket);

            newSocket.on('disconnect', () => {
              setSocket(null);
            });
          }
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        setIsAuthenticated(false);
        navigate('/'); // Redirect to home/login
      } finally {
        setLoading(false); // Stop loading
      }
    };

    checkAuthentication();

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
