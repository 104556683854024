import React from "react";
import Logo from "../Assets/logo.png";
import "../css/navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock,faHouse, faCompass, faRightFromBracket, faUserCircle, faPlus, faSearch, faBars,faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { useNavigate ,Link} from 'react-router-dom';
import useAuth from "../components/authmiddle/useAuth";
import axios from 'axios'
import SearchComponent from "../components/SearchComponent";



const Navbar = () => {
    const { authenticated ,setAuthenticated} = useAuth();
    

    const navigate=useNavigate();

    const handleLogout = async () => {
        try {
          // Call the logout API
         const result=await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/logout`, {}, {
            withCredentials: true // Ensure cookies are included in the request
          });

          
          setAuthenticated(false)
        
          // Redirect to login or homepage after successful logout
          if (result.status === 201) {
            // Redirect to home or dashboard
            alert('You are Logged Out Successfully!')
            navigate('/login');
            window.location.reload()
        }
          
         
        } catch (error) {
          console.error('Logout error:', error);
          window.location.reload();
        }
      };
  
    
    return (
        <>
            <div id="Container_1">
                <div className="Logo">
                    <Link to="/">
                        <img src={Logo} alt="Logo" />
                    </Link>
                </div>
                <div className="search-container">
                    <input className="Search_Input" placeholder="Find cars, bikes, freelancers..." />
                    <button className="Search_Button">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                <div className="Navbar-Home-icon">
                    <Link to="/">
                        <FontAwesomeIcon icon={faHouse} />
                        <p>Home</p>
                    </Link>
                </div>
                <div className="Navbar-Explore-icon">
                    <Link to="/product">
                        <FontAwesomeIcon icon={faCompass} />
                        <p>Explore</p>
                    </Link>
                </div>
                {authenticated &&<div className="Navbar-logout-icon">
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    <button onClick={handleLogout}>
                        <p>Logout</p>
                    </button>
                </div>}
                {authenticated &&<div className="Navbar-profile-icon">
                    <Link to="/profile">
                        <FontAwesomeIcon icon={faUserCircle} className="Navbar-profile-icon-desktop"/>
                        <FontAwesomeIcon icon={faCircleUser} className="Navbar-profile-icon-mobile"/>
                    </Link>
                </div>}
                {!authenticated &&<div className="Navbar-profile-icon">
                    <Link to="/auth">
                        <FontAwesomeIcon icon={faUserLock} className="Navbar-profile-icon-desktop"/>
                        <FontAwesomeIcon icon={faCircleUser} className="Navbar-profile-icon-mobile"/>
                    </Link>
                </div>}
                <div className="Navbar-Create-btn">
                    <Link href="/">
                        <FontAwesomeIcon icon={faPlus} className="Navbar-Create-btn-icon"/>
                        <p>Post Ad</p>
                    </Link>
                </div>
                <div className="Navbar-Toggle-btn" > {/* New Toggle Button */}
                    <a href="">
                        <FontAwesomeIcon icon={faBars} />
                    </a>
                </div>
            </div>
            <div className="container_2">
            <div className="search-containe2">
                    {/* <input className="Search_Input" placeholder="Find cars, bikes, freelancers..." />
                    <FontAwesomeIcon icon={faSearch}/> */}
                    <SearchComponent/>
            </div>
            
            </div>
            {/* <div className="location-container">
                <FontAwesomeIcon icon={faLocationDot}/>
            <p>Deliver to Ludhiana</p>
            </div> */}
        </>
    );
}

export default Navbar;
