import React from "react";
import '../css/tutorial.css'; // Importing CSS file

const Tutorial = () => {
  return (
    <div className="tutorial-container">
      <h1>How it works</h1>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/o744fOLyPDk?si=LC8SbcVQedYWZQCh"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Tutorial;
