// import React,{useState} from 'react';

// import '../../css/favorites.css';
// import FavoriteCard from '../../components/FavoriteCard';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTableCellsLarge,faChevronDown,faBars } from '@fortawesome/free-solid-svg-icons';


// const Results = [
//     {
//         user:'sudhi nahar',
//         name: 'Volkswagen Polo',
//         location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
//         price: '₹2,500',
//         category: 'Cars',
//         images: [
//             'https://goleezy.com/wp-content/uploads/2024/06/d82e8b84-49c9-48cf-bac0-cb671547331a-720x640.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/e58f24e2-009b-48cc-a5d8-65eb04f8f320-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/02820e27-ce90-4fcc-b7e3-fd12a7dc8f58-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/a5d76852-682c-4ee2-aeb2-975ed0f8d470-360x320.jpg'
//         ],
//     },
//     {
//         user:'ramesh oberoy',
//         name: 'Volkswagen VENTO',
//         location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
//         price: '₹3,000',
//         category: 'Cars',
//         images: [
//             'https://goleezy.com/wp-content/uploads/2024/06/b6fb0829-259a-4325-8a82-3e330eb4045e-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/c8d6f6c1-957f-442d-af53-e3059b3a322b-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/e7664cd0-24c7-4066-8d23-c65d11a46e7c-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/ae4039d2-abcc-4587-b65c-b2f03a137446-360x320.jpg'
//         ],
//     },
//     {
//         user:'sudhi nahar',
//         name: 'Volkswagen Polo',
//         location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
//         price: '₹2,500',
//         category: 'Cars',
//         images: [
//             'https://goleezy.com/wp-content/uploads/2024/06/d82e8b84-49c9-48cf-bac0-cb671547331a-720x640.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/e58f24e2-009b-48cc-a5d8-65eb04f8f320-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/02820e27-ce90-4fcc-b7e3-fd12a7dc8f58-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/a5d76852-682c-4ee2-aeb2-975ed0f8d470-360x320.jpg'
//         ],
//     },
//     {
//         user:'ramesh oberoy',
//         name: 'Volkswagen VENTO',
//         location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
//         price: '₹3,000',
//         category: 'Cars',
//         images: [
//             'https://goleezy.com/wp-content/uploads/2024/06/b6fb0829-259a-4325-8a82-3e330eb4045e-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/c8d6f6c1-957f-442d-af53-e3059b3a322b-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/e7664cd0-24c7-4066-8d23-c65d11a46e7c-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/ae4039d2-abcc-4587-b65c-b2f03a137446-360x320.jpg'
//         ],
//     },
//     {
//         user:'sudhi nahar',
//         name: 'Volkswagen Polo',
//         location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
//         price: '₹2,500',
//         category: 'Cars',
//         images: [
//             'https://goleezy.com/wp-content/uploads/2024/06/d82e8b84-49c9-48cf-bac0-cb671547331a-720x640.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/e58f24e2-009b-48cc-a5d8-65eb04f8f320-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/02820e27-ce90-4fcc-b7e3-fd12a7dc8f58-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/a5d76852-682c-4ee2-aeb2-975ed0f8d470-360x320.jpg'
//         ],
//     },
//     {
//         user:'ramesh oberoy',
//         name: 'Volkswagen VENTO',
//         location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
//         price: '₹3,000',
//         category: 'Cars',
//         images: [
//             'https://goleezy.com/wp-content/uploads/2024/06/b6fb0829-259a-4325-8a82-3e330eb4045e-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/c8d6f6c1-957f-442d-af53-e3059b3a322b-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/e7664cd0-24c7-4066-8d23-c65d11a46e7c-360x320.jpg',
//             'https://goleezy.com/wp-content/uploads/2024/06/ae4039d2-abcc-4587-b65c-b2f03a137446-360x320.jpg'
//         ],
//     }
// ];

// function Favorites() {
//     const [sortOption, setSortOption] = useState('Newest');
//     const [isListView, setIsListView] = useState(true);
//     const [isOpen, setIsOpen] = useState(false);

//     const handleSortChange = (value) => {
//         setSortOption(value);
//         setIsOpen(false)
//     };

//     const toggleView = (view) => {
//         setIsListView(view === 'list');
//     };

//     return (
//         <div className="favorites">
//              <div className="favorites-header">
//             <span className="favorites-result-count">{Results.length} Results</span>
//             <div className='favorites-header-right'>

//             <div className="favorites-sort-options">
//       <label htmlFor="favorites-sort-select" className='favorites-sort-select-span' style={{color:'#374B5C'}}>Sort by:</label>
//       <div 
//         className="custom-select"
//         onClick={() => setIsOpen(!isOpen)} // Toggle the dropdown
//       >
//         <div className="selected-option">
//           {sortOption}
//         </div>
//         {isOpen && (
//           <ul className="custom-select-options">
//             <li onClick={() => handleSortChange('Newest')}>Newest</li>
//             <li onClick={() => handleSortChange('Oldest')}>Oldest</li>
//           </ul>
//         )}
//         <FontAwesomeIcon icon={faChevronDown} className="favorites-select-icon" />
//       </div>
//     </div>
//             <div className="favorites-view-toggle">
//                 <button
//                     className={`favorites-toggle-button ${!isListView ? 'favorites-active' : ''}`}
//                     onClick={() => toggleView('grid')}
//                 >
//                     <FontAwesomeIcon icon={faTableCellsLarge} className='favorites-ico' />

//                 </button>
//                 <button
//                     className={`favorites-toggle-button ${isListView ? 'favorites-active' : ''}`}
//                     onClick={() => toggleView('list')}
//                 >
//                     <FontAwesomeIcon icon={faBars} className='favorites-ico'/>
//                 </button>
//             </div>
//             </div>
//         </div>
//             <div className="favorite-list">
//                 {Results.map((result, index) => (
//                     <FavoriteCard key={index} data={result}/>
                    
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Favorites;




import React, { useState } from 'react';
import '../../css/favorites.css';
import FavoriteCard from '../../components/FavoritePageCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCellsLarge, faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons';


const Results = [
    {
        user:'sudhi nahar',
        name: 'Volkswagen Polo',
        location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
        price: '₹2,500',
        category: 'Cars',
        images: [
            'https://goleezy.com/wp-content/uploads/2024/06/d82e8b84-49c9-48cf-bac0-cb671547331a-720x640.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/e58f24e2-009b-48cc-a5d8-65eb04f8f320-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/02820e27-ce90-4fcc-b7e3-fd12a7dc8f58-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/a5d76852-682c-4ee2-aeb2-975ed0f8d470-360x320.jpg'
        ],
    },
    {
        user:'ramesh oberoy',
        name: 'Volkswagen VENTO',
        location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
        price: '₹3,000',
        category: 'Cars',
        images: [
            'https://goleezy.com/wp-content/uploads/2024/06/b6fb0829-259a-4325-8a82-3e330eb4045e-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/c8d6f6c1-957f-442d-af53-e3059b3a322b-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/e7664cd0-24c7-4066-8d23-c65d11a46e7c-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/ae4039d2-abcc-4587-b65c-b2f03a137446-360x320.jpg'
        ],
    },
    {
        user:'sudhi nahar',
        name: 'Volkswagen Polo',
        location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
        price: '₹2,500',
        category: 'Cars',
        images: [
            'https://goleezy.com/wp-content/uploads/2024/06/d82e8b84-49c9-48cf-bac0-cb671547331a-720x640.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/e58f24e2-009b-48cc-a5d8-65eb04f8f320-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/02820e27-ce90-4fcc-b7e3-fd12a7dc8f58-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/a5d76852-682c-4ee2-aeb2-975ed0f8d470-360x320.jpg'
        ],
    },
    {
        user:'ramesh oberoy',
        name: 'Volkswagen VENTO',
        location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
        price: '₹3,000',
        category: 'Cars',
        images: [
            'https://goleezy.com/wp-content/uploads/2024/06/b6fb0829-259a-4325-8a82-3e330eb4045e-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/c8d6f6c1-957f-442d-af53-e3059b3a322b-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/e7664cd0-24c7-4066-8d23-c65d11a46e7c-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/ae4039d2-abcc-4587-b65c-b2f03a137446-360x320.jpg'
        ],
    },
    {
        user:'sudhi nahar',
        name: 'Volkswagen Polo',
        location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
        price: '₹2,500',
        category: 'Cars',
        images: [
            'https://goleezy.com/wp-content/uploads/2024/06/d82e8b84-49c9-48cf-bac0-cb671547331a-720x640.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/e58f24e2-009b-48cc-a5d8-65eb04f8f320-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/02820e27-ce90-4fcc-b7e3-fd12a7dc8f58-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/a5d76852-682c-4ee2-aeb2-975ed0f8d470-360x320.jpg'
        ],
    },
    {
        user:'ramesh oberoy',
        name: 'Volkswagen VENTO',
        location: 'Indirapuram, Ghaziabad, Uttar Pradesh, India',
        price: '₹3,000',
        category: 'Cars',
        images: [
            'https://goleezy.com/wp-content/uploads/2024/06/b6fb0829-259a-4325-8a82-3e330eb4045e-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/c8d6f6c1-957f-442d-af53-e3059b3a322b-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/e7664cd0-24c7-4066-8d23-c65d11a46e7c-360x320.jpg',
            'https://goleezy.com/wp-content/uploads/2024/06/ae4039d2-abcc-4587-b65c-b2f03a137446-360x320.jpg'
        ],
    }
];

function Favorites() {
    const [sortOption, setSortOption] = useState('Newest');
    const [isListView, setIsListView] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const handleSortChange = (value) => {
        setSortOption(value);
        setIsOpen(false);
    };

    const toggleView = (view) => {
        setIsListView(view === 'list');
    };

    return (
        <div className="favorites">
            <div className="favorites-header">
                <span className="favorites-result-count">{Results.length} Results</span>
                <div className="favorites-header-right">
                    <div className="favorites-sort-options">
                        <label htmlFor="favorites-sort-select" className="favorites-sort-select-span" style={{ color: '#374B5C' }}>Sort by:</label>
                        <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                            <div className="selected-option">{sortOption}</div>
                            {isOpen && (
                                <ul className="custom-select-options">
                                    <li onClick={() => handleSortChange('Newest')}>Newest</li>
                                    <li onClick={() => handleSortChange('Oldest')}>Oldest</li>
                                </ul>
                            )}
                            <FontAwesomeIcon icon={faChevronDown} className="favorites-select-icon" />
                        </div>
                    </div>
                    <div className="favorites-view-toggle">
                        <button
                            className={`favorites-toggle-button ${!isListView ? 'favorites-active' : ''}`}
                            onClick={() => toggleView('grid')}
                        >
                            <FontAwesomeIcon icon={faTableCellsLarge} className="favorites-ico" />
                        </button>
                        <button
                            className={`favorites-toggle-button ${isListView ? 'favorites-active' : ''}`}
                            onClick={() => toggleView('list')}
                        >
                            <FontAwesomeIcon icon={faBars} className="favorites-ico" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={`favorite-list ${isListView ? 'list-view' : 'grid-view'}`}>
                {Results.map((result, index) => (
                    <FavoriteCard key={index} data={result} />
                ))}
            </div>
        </div>
    );
}

export default Favorites;

