import React, { useState } from 'react';
import '../css/ProfileHeading.css';

const  ProfileHeading = ({ activeTab = 'Add New',isButton=true }) => {
  const [activeButton, setActiveButton] = useState('myPackages');
  
const clicked=()=>{
  setActiveButton('myPackages');
  console.log("hello")
  window.location.href="/package"
}
  return (
    <div className="settings-container">
      <h2 className="settings-title">{activeTab}</h2>
      {isButton &&<div className="settings-buttons">
        <button
          className={`settings-button ${activeButton === 'myPackages' ? 'active' : ''}`}
          onClick={() => setActiveButton('myPackages')}
        >
          My Packages
        </button>
        <button
          className={`settings-button ${activeButton === 'buyPackage' ? 'active' : ''}`}
          onClick={clicked}
        >
          Buy the Package
        </button>
      </div>}
    </div>
  );
};

export default ProfileHeading;
